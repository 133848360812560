<script setup>
import {onMounted ,ref} from "vue";
import router from "@/router";
import store from "@/store";
import speak from "../../../plugins/speak";

const prize   = ref({
  title: "",
  description: "",
})
onMounted( async () => {
  try {
    const response = await store.dispatch("setPrizeAction")
    prize.value = response.data;
  } catch (e) {
    router.push('/bvi/playing/')
  }
});
</script>

<template>
  <main class="main">
    <section class="container">
      <div class="content">
        <h4 class="h4" @onmouseover="speak">ВАШ ПРИЗ</h4>
        <h3 class="h3" v-html="prize.title" @onmouseover="speak"/>
        <div class="desc" v-html="`<p>${prize.description}</p>`" @onmouseover="speak"/>
        <a class="button --back bvi-no-styles" href="/bvi/workplace" @onmouseover="speak">К РАБОЧЕМУ СТОЛУ</a>
      </div>
    </section>
  </main>
</template>

<style scoped lang="scss">
.main {
  display: flex;
  align-items: center;
  height: auto;
  min-height: 100vh;
  overflow: hidden;
  background: #202020;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 45px 0;
  text-align: center;
  @media (max-width: 1440px) {
    height: min-content;
  }
  @media (max-width: 768px) {
    grid-gap: 10px;
    grid-template-rows: auto;
    padding: 45px 0;
  }

  .h4 {
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 1280px) {
      grid-area: 1/1/2/3;
    }
    @media (max-width: 768px) {
      text-align: center !important;
    }
  }

  .h3 {
    margin-bottom: auto;
    text-transform: uppercase;
    z-index: 1;

    @media (max-width: 1280px) {
      grid-area: 2/1/3/3;
    }
    @media (max-width: 768px) {
      text-align: center !important;
    }
    @media screen and (orientation:landscape) and (max-width: 1024px), (max-width: 425px) {
      grid-area: 2/1/3/2;
    }
  }

  .desc {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    padding-top: 30px;
    @extend %font-inter-300;
    font-size: 34px;
    line-height: 1.17;

    a {
      text-decoration: underline;
      text-decoration-color: inherit;
      text-underline-offset: 0.2rem;
      text-decoration-thickness: 0.01rem;
    }

    @media (max-width: 1440px) {
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      grid-area: 3/1/4/2;
      max-width: initial;
      justify-content: initial;
      padding-top: initial;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      grid-area: 4/1/5/3;
      text-align: center;
      margin-bottom: 25px;
    }
    @media screen and (orientation:landscape) and (max-width: 1024px), (max-width: 425px) {
      font-size: 12px;
    }

    p:not(:last-child) {
      margin-bottom: 25px;
    }
  }

  .prize {
    display: flex;
    justify-content: center;
    z-index: 0;

    @media (max-width: 1280px) {
      grid-area: 3/2/5/3;
      transform: translateY(-25px);
    }
    @media (max-width: 1024px) {
      max-width: 260px;
    }
    @media (max-width: 768px) {
      grid-area: 3/1/4/3;
      height: 100%;
      margin: 0 auto;
      transform: initial;
    }
    @media screen and (orientation:landscape) and (max-width: 1024px), (max-width: 425px) {
      grid-area: 1/2/5/3;
    }

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center bottom;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-appearance: none;
    }
  }

  .button {

    @media (max-width: 1440px) {
      height: 70px;
      padding: 0 30px;
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      max-width: max-content;
    }
    @media (max-width: 768px) {
      height: 50px;
      margin: 0 auto;
      font-size: 18px;
    }

    @media screen and (orientation:landscape) and (max-width: 1024px) {
      height: 50px;
      font-size: 18px;
    }
  }

}
</style>