import store from "@/store";

export default class StatusesPanel {
    constructor(scene) {
        this.scene = scene;
        this.statuses = store.getters.getStatuses;
        this.bages = {}
        this.panel
    }

    addStatusesUI() {
        // Вычисляем координаты и размеры кнопок
        let buttonSpacing = 15;
        let buttonsPerRow = 2;
        let startX = 100;
        let startY = 50;
        let currentX = 0;
        let currentY = 0;
        let i = 0;

        const title = this.scene.add.text(startX, startY, 'ПАРАМЕТРЫ СТАЖЁРА', { fontFamily: 'Unbounded', fontSize: 16, color: '#4d4d4d' }).setAlpha(.7)

        startY += title.height + buttonSpacing;

        this.panel = this.scene.add.container(startX, startY);

        for (var key in this.statuses) {
            const statusName = this.getStatusName(key, this.statuses[key].count);

            const bageTexture = this.scene.add.image(currentX, currentY, key).setOrigin(0, 0)
            const bage = this.scene.add.text(currentX + 66, currentY + 15, `${statusName}`, { fontFamily: 'Unbounded', fontSize: 12, color: '#ffffff' });

            const buttonWidth = bageTexture.width;
            const buttonHeight = bageTexture.height;

            const status_up = this.scene.add.image(currentX + buttonWidth, currentY, 'status_up').setAlpha(0)
            const status_down = this.scene.add.image(currentX + buttonWidth, currentY, 'status_down').setAlpha(0)

            this.bages[key] = {bage: bage, up: status_up, down: status_down, bageTexture: bageTexture};

            this.panel.add(bageTexture);
            this.panel.add(bage);
            this.panel.add(status_up);
            this.panel.add(status_down);


            // Увеличиваем текущие координаты для следующей кнопки
            currentX += buttonWidth + buttonSpacing;



            // Если достигнут конец строки, переходим на следующую строку
            if ((i + 1) % buttonsPerRow === 0) {
                currentX = 0;
                currentY += buttonHeight + buttonSpacing;
            }
            i++
        }
    }


    getStatusName(status, count) {
        return store.getters.getStatusName(status, count);
    }

    updateStatuses(statuses) {
        statuses.forEach(status=>{
            store.dispatch('updateStatusAction', status);
            if (status.count > 0) {
                this.bages[status.status].up.setAlpha(1)
                setTimeout(()=>{
                    this.bages[status.status].up.setAlpha(0)
                }, 1000)
            } else {
                this.bages[status.status].down.setAlpha(1)
                setTimeout(()=>{
                    this.bages[status.status].down.setAlpha(0)
                }, 1000)
            }
            const count = store.getters.getStatus(status.status);
            const updatedStatusName = this.getStatusName(status.status, count);
            this.bages[status.status].bage.setText(`${updatedStatusName}`);
        })
    }
}