<script setup>
import {ref} from "vue";

const step = ref(1);

</script>

<template>
  <main class="main company">
    <section v-if="step === 1" class="container">
      <h1 class="h2" @mouseover="speak">Куда отправитесь
        стажироваться?</h1>
      <p @mouseover="speak">На&nbsp;сайте «Профразвития» вы&nbsp;выбрали интересную стажировку, заполнили анкету и&nbsp;прошли оценку компетенций. Спустя несколько дней, о&nbsp;чудо, выполнили тестовое задание, и&nbsp;получили приглашение на&nbsp;собеседование…, которое&nbsp;с&nbsp;блеском прошёл! Теперь дело за&nbsp;малым&nbsp;— пройти стажировку мечты.</p>
      <button class="button" title="Продолжить" @click="()=>step++" @mouseover="speak">Продолжить</button>
    </section>
    <section v-if="step === 2" class="container">
      <p class="h4" @mouseover="speak">ВАША КОМПАНИЯ</p>
      <h1 class="h2" @mouseover="speak">КОМПАНИЯ<br>
        МЕЧТЫ ПЛЮС</h1>
      <p @mouseover="speak">Здесь каждый сотрудник чувствует себя ценным и&nbsp;важным, а&nbsp;также развивается и&nbsp;растёт профессионально. Гибкий график позволяет сочетать работу и&nbsp;личную жизнь, а&nbsp;уникальные проекты&nbsp;— дают возможности реализовывать свои идеи и&nbsp;творческий потенциал.
        <br><br>
        А&nbsp;вы&nbsp;как&nbsp;хотели? В&nbsp;проекте «Профразвитие» вас&nbsp;ждут только классные компании.</p>
      <router-link to="/bvi/playing" class="button bvi-no-styles" title="на стажировку" @mouseover="speak">НА СТАЖИРОВКУ</router-link>
    </section>
  </main>
</template>

<style scoped lang="scss">
  .company  {
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    .container {
      max-width: 1400px;
    }
    .h2 {
      margin-bottom: 60px;
    }
    .h4 {
      margin-bottom: 30px;
    }
    p {
      margin-bottom: 100px;
    }
    .button {
      width: 100%;
      max-width: 663px;
      margin: 0 auto;
    }
  }
</style>