<script setup>
import {defineProps} from 'vue';
import speak from "../../../../plugins/speak";

const props = defineProps({
  title: {
    type: String,
    required: true,
  }
})
</script>

<template>
  <div class="statuses-title font-inter" @mouseover="speak">
    {{props.title}}
  </div>
</template>

<style scoped lang="scss">
.statuses-title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 16px;
}
</style>