<script setup>
import {ref, onMounted, defineExpose, inject, defineEmits} from 'vue'

import { gsap } from 'gsap';
import { WebGL1Renderer, Scene, PerspectiveCamera, DoubleSide, PCFSoftShadowMap, ACESFilmicToneMapping, AmbientLight, RectAreaLight, Fog } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import store from "@/store";
// import router from "@/router";

// Variables: -----------------------------------------------
const rotationDrum    = ref();
const showBtnNextPage = inject('showBtnNextPage');
const emit = defineEmits(["ready"]);
// Hooks: ---------------------------------------------------
onMounted(() => {
  if (store.getters.isBVImode) {
    window.location.href = window.location.origin + '/bvi/company'
  }

  let scene, renderer, camera, modelDrum;

  const color_drum = 0xffffff;
  const scale_drum = 0.3;

  const initScene = () => {
    renderer = new WebGL1Renderer({ antialias: true, alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type    = PCFSoftShadowMap;
    renderer.toneMapping       = ACESFilmicToneMapping;

    document.querySelector("#drum").appendChild(renderer.domElement);

    scene = new Scene();

    const color = 0x252525;
    const near  = 5;
    const far   = 18;
    scene.fog   = new Fog(color, near, far);
  }

  const initCamera = () => {
    const fov    = 75;
    const aspect = window.innerWidth / window.innerHeight;
    const near   = 0.1;
    const far    = 1000;

    camera = new PerspectiveCamera(fov, aspect, near, far);

    camera.position.set(0, 0, 16);
    camera.rotation.set(0, 0, 0);
  }

  const initLights = () => {
    const setLook = light => light.lookAt(scene.position)

    const widthRectLight     = 22;
    const heightRectLight    = 2.2;
    const intensityRectLight = 1.2;
    const closeRectLight     = new RectAreaLight(color_drum, intensityRectLight, widthRectLight, heightRectLight);
    closeRectLight.position.set(0, 0, 6.6);
    setLook(closeRectLight);

    const widthRectLight2     = 22;
    const heightRectLight2    = 3.2;
    const intensityRectLight2 = 2;
    const farRectLight        = new RectAreaLight(color_drum, intensityRectLight2, widthRectLight2, heightRectLight2);
    farRectLight.position.set(0, 0, 9);
    setLook(farRectLight);

    const ambientLight = new AmbientLight(color_drum, 0.3);
    const arrLight     = [ ambientLight, closeRectLight, farRectLight ];

    arrLight.forEach(light => scene.add(light));
  }

  const resizeRendererToDisplaySize = renderer => {
    const canvas       = renderer.domElement;
    const windowWidth  = canvas.clientWidth;
    const windowHeight = canvas.clientHeight;
    const needResize   = canvas.width !== windowWidth || canvas.height !== windowHeight;
    return needResize;
  }

  const responsiveModel = canvas => {
    if (window.innerWidth <= 320) {
      canvas.style.height = window.innerHeight / 1.25 + 'px';
    } else if (window.innerWidth <= 650) {
      canvas.style.height = window.innerHeight / 1.55 + 'px';
    } else if (window.innerWidth <= 820 && window.innerHeight <= 380) {
      canvas.style.height = window.innerHeight / 0.75 + 'px';
    } else {
      canvas.style.height = window.innerHeight + 'px';
    }
  }

  const loadGLTFModel = () => {

    const _load = gltf => {
      modelDrum = gltf.scene;

      // Донастройка материалов
      modelDrum.traverse((child) => {
        if (child.isMesh) {
          child.material.side        = DoubleSide;
          child.castShadow           = true;
          child.receiveShadow        = true;
          child.material.opacity     = 0.0;
          child.material.transparent = true;
        }
      })
      scene.add(modelDrum);

      // Удаление из общей группы меша "selectedOption"
      let objLight;
      const scaleChild = 0.9;
      modelDrum.traverse((child) => {
        if (child.isMesh) {
          child.rotation.x = Math.PI;
          child.scale.set(scaleChild, scaleChild, scaleChild);
          if (child.name == 'selectedOption') {
            objLight = child;
            modelDrum.remove(child);
          }
        }
      })
      scene.add(objLight);

      modelDrum.scale.set(scale_drum, scale_drum, scale_drum);
      objLight.scale.set(scale_drum * 0.89, scale_drum * 0.89, scale_drum * 0.89);

      const arrModels = [ modelDrum, objLight ];

      // Анимации появления и исчезаия
      const timeStart     = 0.4;
      const timeFinish    = 0.4;
      const opacityStart  = 1.0;
      const opacityFinish = 0.0;
      const scaleStart    = 2.0;
      const scaleFinish   = 0.5;
      //const timeRotation  = 8;

      const opacityAnimation = (mesh, time, transparency) => {
        gsap.to(mesh.material, {
          opacity: transparency,
          duration: time,
          ease: 'power2.inOut'
        });
      }

      const scaleAnimation = (arr, scale, time) => {
        arr.forEach((model) => {
          gsap.to(model.scale, {
            x: model.scale.x * scale,
            y: model.scale.y * scale,
            z: model.scale.z * scale,
            duration: time,
            ease: 'power2.inOut'
          });
        });
      }



      const startAnimation = arrModels => {
        scaleAnimation(arrModels, scaleStart, timeStart);

        modelDrum.traverse((child) => {
          if (child.isMesh) {
            opacityAnimation(child, timeStart, opacityStart);
          }
        })
        opacityAnimation(objLight, timeStart, opacityStart);
      }

      const finshAnimation = arrModels => {
        scaleAnimation(arrModels, scaleFinish, timeFinish);
        modelDrum.traverse(child => child.isMesh ? opacityAnimation(child, timeFinish, opacityFinish) : null)
        opacityAnimation(objLight, timeFinish, opacityFinish);
      }

      // Анимация поворота барабана
      modelDrum.rotation.set(Math.PI, 0, 0);
      // const rotationAngle = Math.PI * 2;

      // Переключение анимации появления и исчезания
      let currentState = 'finish';
      let mouseX       = 0;
      let mouseY       = 0;

      const switchingAnimation = () => {
        currentState = (currentState === 'start') ? 'finish' : 'start';
        currentState === 'start' ? startAnimation(arrModels) : finshAnimation(arrModels);
      }

      const parallax = () => {
        document.addEventListener('mousemove', function (e) {
          const windowHalfX = window.innerWidth / 2;
          const windowHalfY = window.innerHeight / 2 + window.innerHeight / 15;
          mouseX            = (e.clientX - windowHalfX) / 500;
          mouseY            = (e.clientY - windowHalfY) / 500;
        });
      };

      const render = () => {
        renderer.render(scene, camera);
      }

      const animate = () => {
        camera.position.x += (mouseX - camera.position.x) * 0.08;
        camera.position.y += (-mouseY - camera.position.y) * 0.08;
        camera.lookAt(scene.position);

        const canvas = renderer.domElement;
        responsiveModel(canvas);
        if (resizeRendererToDisplaySize(renderer)) {
          const canvas  = renderer.domElement;
          camera.aspect = canvas.clientWidth / canvas.clientHeight;
          camera.updateProjectionMatrix();
        }

        render();
        requestAnimationFrame(animate);
        setTimeout(()=>{emit('ready')}, 500)
      }

      switchingAnimation();
      parallax();
      animate();
    }

    const _progress = () => { /*console.dir(xhr)*/
    }

    const _error = error => console.error(error)

    const model = "/karusel7.glb";
    new GLTFLoader().load(model, gltf => _load(gltf), xhr => _progress(xhr), error => _error(error));

  }

  const startAnimation = () => {
    // Анимации появления, исчезаия и поворота барабана
    let timeRotation  = 0.0008;
    let rotationAngle = Math.PI * 0.333;

    const rotationMesh = (mesh, sign, rotate) => {
      gsap.to(mesh.rotation, {
        x: mesh.rotation.x - rotationAngle * sign,
        duration: timeRotation,
        ease: 'power2.inOut',
        onComplete: () => {
          if (rotate) {
            // показать кнопку перехода на следующую страницу
            setTimeout(() => {
              showBtnNextPage(true)
            }, 1500)
          }
        }
      });
    }

    setTimeout(() => {
      let interval = setInterval(() => {
        if (typeof modelDrum !== 'undefined') {
          rotationMesh(modelDrum, -1, false);
          modelDrum.traverse(child => child.isMesh ? rotationMesh(child, 1, false) : null)
          gsap.to("#drum", { opacity: 1, duration: .4 });
          clearInterval(interval);
        }
      }, 100)
    }, 500)

    rotationDrum.value = () => {
      timeRotation  = 8;
      rotationAngle = Math.PI * (4 - 0.333);

      rotationMesh(modelDrum, -1,  true);
      modelDrum.traverse(child => child.isMesh ? rotationMesh(child, 1, true) : null)
    };
  };


  initScene();
  initCamera();
  initLights();
  loadGLTFModel();
  startAnimation();
})

defineExpose({ rotationDrum })
</script>

<template>
  <div id="drum"></div>
</template>

<style lang="scss" scoped>
#drum {
  display: flex;
  align-items: center;
  height: 60vh;
  transform: translateY(10%);
  z-index: -1;
  opacity: 0;

  @media (max-width: 1440px) {
    transform: initial;
  }
  @media (max-width: 968px) {
    transform: translateY(20%);
  }

  @media screen and (orientation:landscape) and (max-width: 1024px) {
    transform: translateY(5%);
  }
}
</style>
