import {Scene} from 'phaser'
import store from "@/store";
import StatusesPanel from "@/game/utils/statusesPanel";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI"

import open_space from '@/game/assets/OpenSpace/open_space.png'
//import setupCamera from "../utils/setupCamera";

export default class OpenSpaceScene extends Scene {
    constructor() {
        super({key: 'OpenSpaceScene'})
        this.statuses
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload() {
        this.assetsLoader.loadAssets()
        this.load.image('open_space', open_space)
    }

    create() {
        if (!store.getters.getMusicStatus) {
            let mainMelody = this.sound.add('main_melody', { loop: true, volume: 0.2 });
            mainMelody.play();
            store.commit('switchMusic');
        }
        this.add.image(0, 0, 'open_space').setOrigin(0);

        this.statuses = new StatusesPanel(this);
        this.statuses.addStatusesUI();

        this.cameras.main.fadeIn(300, 0, 0, 0, );


        const complitedActivities = store.getters.getComplitedActivities;

        const activites = store.getters.getGameActivites;

        if (store.getters.getNotComplitedActivities.length == 0 && store.getters.getPrize) {
            this.scene.start('WorkPlaceScene');
        }

        if (activites.length === complitedActivities.length && !store.getters.getPrize) {
            store.commit('setCurrentActivity', 'dialogAlexFinal');
            store.dispatch('setNeedFinalAction', true);
            this.scene.start('DialogScene');
        }

        // Создаем кнопки из массива строк
        for (let i = 0; i < activites.length; i++) {
            if (!complitedActivities.includes(activites[i].dialog)) {
                // Создаем кнопку
                let pointer = this.add.sprite(activites[i].x, activites[i].y, `pointer-${i}`).setOrigin(0,0);

                // Устанавливаем интерактивность для кнопки
                pointer.setInteractive({ cursor: 'pointer' });

                this.anims.create({
                    key: `blink`,
                    frames: this.anims.generateFrameNumbers('pointer', {start: 0, end: 4}),
                    frameRate: 6,
                    repeat: -1
                })

                pointer.anims.play('blink');

                pointer.on('pointerdown', () => {
                    store.commit('setCurrentActivity', activites[i].dialog);
                    this.cameras.main.fadeOut(300, 0, 0, 0, (camera, progress)=>{
                        if (progress === 1) {
                            this.scene.start('DialogScene');
                        }
                    });

                });
            }
        }

        //setupCamera(this);

        this.sceneUI.addAllUI();
    }

    render() {
        this.debug.inputInfo(32, 32);
    }
}
