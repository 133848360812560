export default function showHelp(scene, help) {
    const helpScreen = scene.add.image(0, 0, help).setOrigin(0,0).setAlpha(0).setDepth(5);
    helpScreen.setInteractive();
    helpScreen.on('pointerdown', () => {
        helpScreen.destroy();
    });
    scene.tweens.add({
        targets: helpScreen,
        alpha: 1,
        duration: 800,
        ease: 'Linear'
    });
}