import dialog_texture from '../../assets/UI/Dialog/dialog.png'
import openspace_help from '../../assets/Help/openspace_help.png'
import button from '../../assets/UI/Dialog/button.png'
import button_hover from '../../assets/UI/Dialog/button_hover.png'
import restart_button from '../../assets/UI/icons/restart_button.png'
import restart_button_hover from '../../assets/UI/icons/restart_button_hover.png'
import exit from '../../assets/UI/icons/exit.png'
import exit_hover from '../../assets/UI/icons/exit_hover.png'
import question from '../../assets/UI/icons/question.png'
import question_hover from '../../assets/UI/icons/question_hover.png'
import screen_mode from '../../assets/UI/icons/screen_mode.png'
import screen_mode_hover from '../../assets/UI/icons/screen_mode_hover.png'
import workplace from '../../assets/UI/icons/workplace.png'
import workplace_hover from '../../assets/UI/icons/workplace_hover.png'
import to_openspace from '../../assets/UI/icons/to_openspace.png'
import to_openspace_hover from '../../assets/UI/icons/to_openspace_hover.png'
import comrades from '../../assets/UI/Statuses/comrades.png'
import director from '../../assets/UI/Statuses/director.png'
import effectiveness from '../../assets/UI/Statuses/effectiveness.png'
import psychological from '../../assets/UI/Statuses/psychological.png'
import status_up from '../../assets/UI/Statuses/status_up.png'
import status_down from '../../assets/UI/Statuses/status_down.png'
import share from '../../assets/UI/icons/share.png'
import telegram from '../../assets/UI/icons/telegram.png'
import vk from '../../assets/UI/icons/vk.png'
import do_request_button from '../../assets/UI/icons/do_request_button.png'
import share_hover from '../../assets/UI/icons/share_hover.png'
import telegram_hover from '../../assets/UI/icons/telegram_hover.png'
import vk_hover from '../../assets/UI/icons/vk_hover.png'
import do_request_button_hover from '../../assets/UI/icons/do_request_button_hover.png'
import achievement from '../../assets/UI/achievement.png'
import pointer from '../../assets/UI/pointer.png'

//Sounds
import keyboard_click from '../../assets/sound/keyboard_click.mp3'
import main_melody from '../../assets/sound/main_melody.mp3'

import store from "@/store";

export default class AssetsLoader {
    constructor(scene) {
        this.scene = scene;
        this.assets = [
            {
                source: openspace_help,
                name: 'openspace_help',
                type: 'image'
            },
            {
                source: achievement,
                name: 'achievement',
                type: 'image'
            },
            {
                source: vk,
                name: 'vk',
                type: 'image'
            },
            {
                source: telegram,
                name: 'telegram',
                type: 'image'
            },
            {
                source: do_request_button,
                name: 'do_request_button',
                type: 'image'
            },
            {
                source: share,
                name: 'share',
                type: 'image'
            },
            {
                source: vk_hover,
                name: 'vk_hover',
                type: 'image'
            },
            {
                source: telegram_hover,
                name: 'telegram_hover',
                type: 'image'
            },
            {
                source: do_request_button_hover,
                name: 'do_request_button_hover',
                type: 'image'
            },
            {
                source: share_hover,
                name: 'share_hover',
                type: 'image'
            },
            {
                source: dialog_texture,
                name: 'dialog_texture',
                type: 'image'
            },
            {
                source: button,
                name: 'button',
                type: 'image'
            },
            {
                source: button_hover,
                name: 'button_hover',
                type: 'image'
            },
            {
                source: restart_button,
                name: 'restart_button',
                type: 'image'
            },
            {
                source: restart_button_hover,
                name: 'restart_button_hover',
                type: 'image'
            },
            {
                source: exit,
                name: 'exit',
                type: 'image'
            },
            {
                source: exit_hover,
                name: 'exit_hover',
                type: 'image'
            },
            {
                source: question,
                name: 'question',
                type: 'image'
            },
            {
                source: question_hover,
                name: 'question_hover',
                type: 'image'
            },
            {
                source: screen_mode,
                name: 'screen_mode',
                type: 'image'
            },
            {
                source: screen_mode_hover,
                name: 'screen_mode_hover',
                type: 'image'
            },
            {
                source: workplace,
                name: 'workplace',
                type: 'image'
            },
            {
                source: workplace_hover,
                name: 'workplace_hover',
                type: 'image'
            },
            {
                source: to_openspace,
                name: 'to_openspace',
                type: 'image'
            },
            {
                source: to_openspace_hover,
                name: 'to_openspace_hover',
                type: 'image'
            },
            {
                source: comrades,
                name: 'comrades',
                type: 'image'
            },
            {
                source: director,
                name: 'director',
                type: 'image'
            },
            {
                source: effectiveness,
                name: 'effectiveness',
                type: 'image'
            },
            {
                source: psychological,
                name: 'psychological',
                type: 'image'
            },
            {
                source: status_up,
                name: 'status_up',
                type: 'image'
            },
            {
                source: status_down,
                name: 'status_down',
                type: 'image'
            },
            //Sounds
            {
                source: keyboard_click,
                name: 'keyboard_click',
                type: 'audio'
            },
            {
                source: main_melody,
                name: 'main_melody',
                type: 'audio'
            },
            //Spriteshhets
            {
                source: pointer,
                name: 'pointer',
                type: 'spritesheet',
                frameConfig: {frameWidth: 100, frameHeight: 100},
            },
            //Multiatlases
            {
                source: '/assets/spritesheets/',
                name: 'dialogPapers',
                type: 'multiatlas',
            },
            {
                source: '/assets/spritesheets/',
                name: 'dialogPlanningUp',
                type: 'multiatlas',
            },
            {
                source: '/assets/spritesheets/',
                name: 'dialogPrinter',
                type: 'multiatlas',
            },
            {
                source: '/assets/spritesheets/',
                name: 'dialogComrades',
                type: 'multiatlas',
            },
            {
                source: '/assets/spritesheets/',
                name: 'dialogConditioner',
                type: 'multiatlas',
            },
            {
                source: '/assets/spritesheets/',
                name: 'dialogDefault',
                type: 'multiatlas',
            },
        ]
    }
    showPreloader() {
        var width = this.scene.cameras.main.width;
        var height = this.scene.cameras.main.height;

        var loadingText = this.scene.make.text({
            x: width / 2,
            y: height / 2 - 50,
            text: 'Загрузка...',
            style: {
                font: '20px monospace',
                fill: '#FFFFFF'
            }
        });
        loadingText.setOrigin(0.5, 0.5);

        var loadingBar = this.scene.add.graphics();

        this.scene.load.on('progress', function (value) {
            loadingBar.clear();
            loadingBar.fillStyle(0xF15A29, 1);
            loadingBar.fillRect(width / 2 - 150, height / 2 - 20, 300 * value, 30);
        });

        this.scene.load.on('complete', function () {
            loadingBar.destroy();
            loadingText.destroy();
        });
    }
    loadAssets() {
        this.showPreloader();
        this.scene.plugins.get('rexWebFontLoader').addToScene(this.scene);
        this.scene.load.rexWebFont({
            google: {
                families: ['Inter', 'Unbounded']
            },
            testString: {fontFamily: 'Тестовая строка'},
            testInterval: 20,
        });
        this.assets.forEach(asset=>{
            let activity = store.getters.getCurrentActivity;
            let dialogData = store.getters.getDialog(activity);
            switch (asset.type) {
                case 'spritesheet':
                    this.scene.load.spritesheet(asset.name, asset.source, asset.frameConfig);
                    break;
                case 'audio':
                    this.scene.load.audio(asset.name, asset.source);
                    break;
                case 'multiatlas':
                    if (activity) {
                        if (asset.name === dialogData.spritesheet) {
                            this.scene.load.multiatlas(`${asset.name}`, `${asset.source}${asset.name}/${asset.name}.json`);
                        }
                    }


                    break;
                default:
                    this.scene.load.image(asset.name, asset.source);
            }
        })
    }
    isTextureKeyUsed(key) {
        // Проходимся по списку всех загруженных текстур
        for (let textureKey in this.scene.textures.list) {
            if (textureKey === key) {
                return true; // Если ключ найден, возвращаем true
            }
        }
        return false; // Если ключ не найден, возвращаем false
    }
}