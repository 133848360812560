import {Scene} from 'phaser'
import store from "@/store";

import AssetsLoader from "@/game/utils/AssetsLoader"
import UI from "@/game/utils/UI"


//Assets
import work_place from '@/game/assets/WorkPlace/work_place.png'
import areiopagos_achievement from '@/game/assets/WorkPlace/achievements/areiopagos.png'
import headphones_achievement from '@/game/assets/WorkPlace/achievements/headphones.png'
import conditioner_achievement from '@/game/assets/WorkPlace/achievements/conditioner.png'
import capibara_achievement from '@/game/assets/WorkPlace/achievements/capibara.png'
import keyCard_achievement from '@/game/assets/WorkPlace/achievements/keyCard.png'
import comrades_achievement from '@/game/assets/WorkPlace/achievements/comrades.png'
import memolog_achievement from '@/game/assets/WorkPlace/achievements/memolog.png'
import prize from '@/game/assets/WorkPlace/prize.png'
import statistic from '@/game/assets/WorkPlace/statistic.png'
import logo from '@/game/assets/WorkPlace/logo.png'

export default  class SharedWorkPlaceScene extends  Scene {
    constructor() {
        super({key: 'SharedWorkPlaceScene'});
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
        this.achievements = null;
        this.areiopagos
        this.headphones
        this.conditioner
        this.capibara
        this.keyCard
        this.comrades
        this.prize
        this.memolog
    }

    preload() {
        this.assetsLoader.loadAssets()

        this.load.image('work_place', work_place)
        this.load.image('statistic', statistic)
        this.load.image('logo', logo)
        this.load.image('areiopagos_achievement', areiopagos_achievement)
        this.load.image('headphones_achievement', headphones_achievement)
        this.load.image('conditioner_achievement', conditioner_achievement)
        this.load.image('capibara_achievement', capibara_achievement)
        this.load.image('keyCard_achievement', keyCard_achievement)
        this.load.image('comrades_achievement', comrades_achievement)
        this.load.image('memolog_achievement', memolog_achievement)
        this.load.spritesheet('prize', prize, {frameWidth: 3396 / 3, frameHeight: 510});
    }

    create() {

        this.add.image(0, 0, 'work_place').setOrigin(0);

        this.areiopagos = this.add.image(this.scale.width - 203, 225, 'areiopagos_achievement').setOrigin(0).setAlpha(0);
        this.headphones = this.add.image(1342, 1, 'headphones_achievement').setOrigin(0).setAlpha(0);
        this.conditioner = this.add.image(1335, this.scale.height - 248, 'conditioner_achievement').setOrigin(0).setAlpha(0);
        this.capibara = this.add.image(870, 759, 'capibara_achievement').setAlpha(0);
        this.keyCard = this.add.image(298, 71, 'keyCard_achievement').setOrigin(0, 0).setAlpha(0);
        this.comrades = this.add.image(0, 312, 'comrades_achievement').setOrigin(0, 0).setAlpha(0);
        this.memolog =  this.add.image(1127, 637, 'memolog_achievement').setOrigin(0, 0).setAlpha(0);


        this.achievements = store.getters.getAchievements;
        this.achievements.forEach(achievement=>{
            if (this[achievement]) {
                this[achievement].setAlpha(1).setDepth(2)
            } else {
                console.log(achievement)
            }
        })

        const monitor = this.add.container(517, 172,)
        const textWidth = 420;
        const gap = 25;
        let dinamycY = 0;
        let hovers = [];

        const statisticScreen = this.add.image(textWidth + 13, dinamycY, 'statistic').setOrigin(0,0);

        const logo = this.add.image(0,dinamycY,'logo').setOrigin(0,0);

        dinamycY += gap+logo.height

        const textContent = 'Мы даем возможность попробовать свои силы на реальном рынке труда. Участников ждут лайфхаки, которые помогут подготовиться ко всем этапам трудоустройства. Лучшие смогут получить приглашение на стажировку от партнеров.'
        const text = this.add.text(
            0, dinamycY,
            textContent,
            {
                font: "16px Inter, Arial",
                color: '#4d4d4d',
                fixedWidth: textWidth,
                wordWrap: {width: textWidth, useAdvancedWrap: true},
                lineSpacing: 1.3
            }
        )

        dinamycY += gap+gap+text.height

        const do_request_button = this.add.image(0, dinamycY, 'do_request_button').setOrigin(0, 0);
        do_request_button.setInteractive({cursor: 'pointer'});



        monitor.add(logo)
        monitor.add(do_request_button);
        monitor.add(text)

        hovers = [do_request_button];

        hovers.forEach(item=>{
            item.setDepth(2)
            const hover = this.add.image(item.x, item.y, `${item.texture.key}_hover`).setAlpha(0).setOrigin(0,0);
            hover.setInteractive({cursor: 'pointer'});
            item.on('pointerover', () => {
                item.setAlpha(0);
                hover.setAlpha(1);
            });
            hover.on('pointerdown', ()=> {
                window.open(`https://rsv.ru/internships/`, '_blank');
            })
            hover.on('pointerout', () => {
                item.setAlpha(1);
                hover.setAlpha(0);
            });
            monitor.add(hover)
        })

        monitor.add(statisticScreen);

        let statusOriginY = 122;
        const statuses = store.getters.getStatuses;

        for (let status in statuses) {
            let statusName = store.getters.getStatusName(status, statuses[status].count);
            const statusText = this.add.text(textWidth+128, statusOriginY, statusName.replace(/\n/g, " "), {font: '18px unbounded-400, Arial'});

            dinamycY += gap + statusText.height;

            monitor.add(statusText);

            statusOriginY+=84;
        }
    }
}