<script setup>
import { ref, onBeforeMount, onMounted, provide } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import gsap from "gsap";

// Components: ----------------------------------------------
import Drum from "@/components/drum2";

// Route ----------------------------------------------------
const router = useRouter();

// Store: State/Getters/Actions -----------------------------
const store              = useStore()
let { needFinal, prize } = store.state.game_status

// Variables: -----------------------------------------------
let drum_rotate      = ref(false)
let ref_drum_rotate  = ref()  // Call in children component "Drum"
let tl_error_message = ref(null)
let disabledButton   = ref(false)

// Provide:
let show_button_next = ref(false)

// Functions: -----------------------------------------------
const ref_drumRotate         = () => {
  // Call in children component "Drum"
  ref_drum_rotate.value.drumRotate()
  disabledButton.value = true
}

const errorMessageVisibility = value => {
  let tl = gsap.timeline()
  tl.to(".drum-wrapper", { yPercent: 200, duration: .5 })
  tl.to(".drum-wrapper", { opacity: 0, duration: .5 }, "<90%")
  tl.to(".error-message", { opacity: 1, height: 375, duration: .5 }, "<50%").reversed(!value);
  tl_error_message.value = tl;

  setTimeout(() => {
    tl_error_message.value.reverse()
    setTimeout(() => {
      disabledButton.value = false
    }, 1000)
  }, 3000)

}

// Hooks: ---------------------------------------------------
onBeforeMount(() => {
// Проверяем не перешёл ли пользователь по прямой ссылке.
  !needFinal || prize ? router.push({ path: "/playing/" }) : null
})

onMounted(() => {
  const animations = () => {
    gsap.set([ "span[data-heading-word='1']", "span[data-heading-word='2']", "span[data-heading-word='3']" ], { yPercent: 120 });
    gsap.set(".button-rotate", { opacity: 0, scale: .9 });

    let tl = gsap.timeline()
    tl.to(".heading span[data-heading-word='1']", { yPercent: 0, duration: .5, delay: 1 })
    tl.to(".heading span[data-heading-word='2']", { yPercent: 0, duration: .5 }, "<50%")
    tl.to(".heading span[data-heading-word='3']", { yPercent: 0, duration: .5 }, "<50%")
    tl.to(".button-rotate", { opacity: 1, scale: 1, duration: .5 })
    tl.to(".drum-wrapper", { opacity: 1, duration: .5 }, "<50%")
  }
  animations();
})

// Provide: -------------------------------------------------
provide('showBtnNextPage', () => show_button_next.value = true);
provide('showErrorMessage', value => errorMessageVisibility(value));

</script>

<template>
  <main class="main">
    <section class="container">
      <div class="content">
        <div class="heading-container">
          <h2 class="h2 heading">
            <span v-for="(word, word_i) in ['ВЫБЕРИТЕ&nbsp;', 'СВОЙ', 'ПОДАРОК!']" :key="word_i">
              <span :data-heading-word="word_i + 1" v-html="word"/>
            </span>
          </h2>
          <button class="button-rotate"
                  :disabled="disabledButton"
                  @click="[ref_drumRotate(), drum_rotate= true]">ВРАЩАТЬ БАРАБАН!
          </button>
        </div>

        <div class="error-message">
          <h4 class="h4">ЧТО-ТО ПОШЛО НЕ ТАК</h4>
          <img class="img" :src="require('/src/assets/images/bird.png')" alt="Ваш приз">
          <h4 class="h4">ПОПРОЙБУТЕ ЕЩЕ РАЗ</h4>
        </div>

        <Drum class="drum-wrapper" ref="ref_drum_rotate"/>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>

.main {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  @media (orientation: landscape) {
    height: auto;
    overflow: auto;
  }
  @media (max-width: 1280px) {
    min-height: initial;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 0;
  height: 100%;
}

.heading-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 15vh;

  @media (max-height: 880px) {
    margin-bottom: 150px;
  }
  @media (max-width: 520px) {
    margin-bottom: 100px;
  }

  @media (orientation: landscape) {
    margin-bottom: 15vh;
  }

  .h2 {
    max-width: 1050px;
    text-align: center;
    @media (orientation: landscape) {
      font-size: 35px;
      span:last-child {
        display: block
      }
    }
    span {
      display: inline-block;
      overflow: hidden;
    }
  }

}

/* Анимация шатания краёв */
@keyframes shake {
  0% { transform: rotate(0deg) translateY(130%); }
  20% { transform: rotate(-5deg) translateY(130%); }
  40% { transform: rotate(5deg) translateY(130%); }
  60% { transform: rotate(-3deg) translateY(130%); }
  80% { transform: rotate(3deg) translateY(130%); }
  100% { transform: rotate(0deg) translateY(130%); }
}

/* Анимация блика */
@keyframes blink {
  0% {
    opacity: 0.1;
    left: 150%;
    transform: rotate(45deg);
  }
  50% {
    opacity: 1;
    left: 50%;
    transform: rotate(45deg);
  }
  100% {
    opacity: 0.1;
    left: -150%;
    transform: rotate(45deg);
  }
}

.button-rotate {
  overflow: hidden;
  animation: shake 1s infinite;
  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: rgba(255, 255, 255, 0.5);
    transform: rotate(45deg);
    pointer-events: none;
    animation: blink 3s infinite;
  }
  @media (max-width: 1440px) {
    height: 80px;
    font-size: 32px;
  }
  @media (max-width: 1024px) {
    padding: 0 15px;
    height: 60px;
    font-size: 28px;
  }
  @media (max-width: 768px) {
    padding: 0 15px;
    height: 50px;
    font-size: 26px;
  }
  @media (orientation: landscape) {
    margin-bottom: -15px;
    padding: 0 15px;
    height: 35px;
    font-size: 19px;
  }
  @media (max-width: 520px) {
    height: 35px;
    font-size: 19px;
  }
  @media (max-width: 320px) {
    font-size: 16px;
  }
}

.drum-wrapper {
  opacity: 0;
  max-width: 100vw;
  overflow: hidden;
}

.error-message {
  position: absolute;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transform: translateY(110%);
  height: 0;
  @media (max-width: 1024px) {
    transform: translateY(80%);
  }
  @media (max-width: 768px) {
    transform: translateY(60%);

  }

  img {
    width: 50vw;
    max-width: 250px;
    min-width: 170px;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

</style>
