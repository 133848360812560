<script setup>
import {defineProps} from 'vue';
import speak from "../../../../plugins/speak";
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  statusText: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="status" @mouseover="speak">
    <p class="status-title" >{{props.title}}</p>
    <p class="status-text" v-html="props.statusText"></p>
  </div>
</template>

<style scoped lang="scss">
.status {
  padding: 20px;
  background-color: #414349;
  border-radius: 16px;
  color: #ffffff;
  &-title {
    font-size: 20px;
    margin-bottom: 5px;
    @media (max-width: 1280px) {
      font-size: 13px;
    }
  }
  &-text {
    font-size: 22px;
    font-weight: bold;
    text-transform: uppercase;
    @media (max-width: 1280px) {
      font-size: 15px;
    }
  }
}
</style>