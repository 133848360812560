<template>
  <div>

    <div v-if="gameReady" class="game-container">
      <ion-phaser
          v-bind:game.prop="game"
          v-bind:initialize.prop="true"
      />

    </div>
    <div v-else class="loading">
      <img :src="loadingImage" alt="">
    </div>
    <div v-if="debug" class="debug">
      <p>Ширина окна: {{ game.scale.width }}</p>
      <p>Высота окна: {{ game.scale.height }}</p>
    </div>
    <screenRotateDevice/>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import Phaser from "phaser";
import screenRotateDevice from "@/components/rotate_device"
import ArcadeGameScene from "@/game/scenes/games/ArcadeGameScene";
import MemoryGameScene from "@/game/scenes/games/MemoryGameScene";
import OpenSpaceScene from "@/game/scenes/OpenSpaceScene";
import DialogScene from "@/game/scenes/dialog/DialogScene";
import PreloadingScene from "@/game/scenes/PreloadingScene";
import VirtualJoystickPlugin from 'phaser3-rex-plugins/plugins/virtualjoystick-plugin.js';
import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import store from '@/store';
import preloader from '@/game/assets/loading.gif'
import WorkPlaceScene from "./scenes/WorkPlaceScene";
import authService from "../../plugins/auth";
import { v4 as uuidv4 } from "uuid";
//import router from "@/router";

export default {
  components: {
    screenRotateDevice
  },
  setup() {
    const debug        = false
    const game         = {
      type: Phaser.AUTO,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        parent: 'game-container',
        width: 1900,
        height: 1060
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 300 },
        }
      },
      scene: [ PreloadingScene, OpenSpaceScene, WorkPlaceScene, DialogScene,  MemoryGameScene, ArcadeGameScene, ],
      plugins: {
        global: [
          {
            key: 'rexVirtualJoystick',
            plugin: VirtualJoystickPlugin,
            start: true
          },
          {
            key: 'rexWebFontLoader',
            plugin: WebFontLoaderPlugin,
            start: true
          },
        ]
      }
    }
    let isLoading      = ref(true);
    let userId         = ref(null);
    const loadingImage = preloader

    let isMobile = ref(false)
    const getGameStatus = async () => {
      try {
        if (process.env.NODE_ENV == 'production') {
          await authService.getProfile();
          userId.value = await store.dispatch('getUserId');
        } else {
          userId.value = uuidv4()
        }
        store.dispatch('setUserIdAction', userId.value)
        await store.dispatch('loadGameStatusAction', userId.value)
        isLoading.value = false
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await getGameStatus()
      if (store.getters.isBVImode) {
        window.location.href = window.location.origin + '/bvi/playing'
      }
    });

    const gameReady = computed(() => {
      return !isLoading.value;
    })

    // Attention: "Rotate device".
    window.addEventListener('resize', () => isMobile.value = window.innerWidth <= 420)

    return {
      debug,
      game,
      userId,
      isLoading,
      gameReady,
      getGameStatus,
      loadingImage,
      isMobile,
    }
  }
}


let show_screen_rotate_device = ref(false)
let isMobile                  = ref(false)

isMobile.value = window.innerWidth <= 420
window.addEventListener('resize', () => {
  show_screen_rotate_device.value = window.innerWidth <= 420
  isMobile.value                  = window.innerWidth <= 420
})


</script>

<style lang="scss" scoped>
#app {
  height: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  img {
    width: 150px;
    height: auto;
  }
}

.debug {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 15px;
}

.screen_rotate_device {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-main);
  z-index: 1000;

  /*@media (orientation: portrait) { }*/
  @media (orientation: landscape) {
    display: none;
  }
}
</style>
