import {Scene} from 'phaser'
import preloader from '@/game/assets/loading.gif'
import store from "@/store";
import StatusesPanel from "@/game/utils/statusesPanel";

export default class PreloadingScene extends Scene {
    preload() {
        this.load.image('preloader', preloader)
        this.plugins.get('rexWebFontLoader').addToScene(this);
        this.load.rexWebFont({
            google: {
                families: ['Inter', 'Unbounded']
            },
            testString: {fontFamily: 'Тестовая строка'},
            testInterval: 50,
        });
    }

    create() {
        this.statuses = new StatusesPanel(this);
        this.statuses.addStatusesUI();
        var graphics = this.add.graphics();
        graphics.fillStyle(0x000000, 1);
        graphics.fillRect(0, 0, this.game.config.width, this.game.config.height);
        setTimeout(()=>{
            if (store.getters.getNeedGreetings) {
                store.commit('setCurrentActivity', 'dialogAlexGreetings');
                this.scene.start('DialogScene');
            } else {
                this.scene.start('OpenSpaceScene');
            }
        }, 100)
    }
}