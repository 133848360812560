const state = {
    achievements: {
        comrades: {
            name: "Кружка с капибарой",
            description: "Приветственный подарок от коллег"
        },
        headphones: {
            name: "Наушники",
            description: "Из тайника уволившегося коллеги"
        },
        capibara: {
            name: "Плюшевая капибара",
            description: "За самое оперативное разбирание гор бумаг"
        },
        conditioner: {
            name: "Пульт от кондиционера ",
            description: "Честно отвоеван в нелегкой битве"
        },
        areiopagos: {
            name: "Статуэтка «Самый умный»",
            description: "Потому что вы буквально умнейший, поздравляем!"
        },
        keyCard: {
            name: "Ключ карта",
            description: "Карта-пропуск для самого лучшего стажёра"
        },
        memolog: {
            name: "Мемолог",
            description: 'Сериал "Офис", оказался байопиком о вашей жизни'
        },
    },
};

const getters = {
  getAchievement: state => achievement => {
      return state.achievements[achievement];
  }
}

export default {
    state,
    getters
};