const state = {
    speaker: window.speechSynthesis ? new SpeechSynthesisUtterance() : false,
    talking: false
}

const mutations = {
    switchTalking(state, talking) {
        state.talking = talking
    }
}

const getters = {
    isTalking: state => state.talking,
    getSpeaker: state => state.speaker,
}

export default {
    state,
    mutations,
    getters
};