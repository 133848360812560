<script setup>
import Statuses from "@/components/BVI/HUD/Statuses.vue";
import Control from "@/components/BVI/UI/Control.vue";
import store from "@/store";
import router from "@/router";
import { useRoute } from 'vue-router';

const route = useRoute();
const fullscreen = () => {
  const doc = document.documentElement;

  if (doc.requestFullscreen) {
    doc.requestFullscreen();
  } else if (doc.mozRequestFullScreen) { // Firefox
    doc.mozRequestFullScreen();
  } else if (doc.webkitRequestFullscreen) { // Chrome, Safari и Opera
    doc.webkitRequestFullscreen();
  } else if (doc.msRequestFullscreen) { // Internet Explorer/Edge
    doc.msRequestFullscreen();
  } else {
    alert("Ваш браузер не поддерживает полноэкранный режим.");
  }

  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) { // Firefox
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) { // Chrome, Safari и Opera
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { // IE/Edge
    document.msExitFullscreen();
  } else {
    alert("Ваш браузер не поддерживает выход из полноэкранного режима.");
  }
}

</script>

<template>
  <div class="interface bvi-no-styles">
    <Statuses />
    <div class="controls bvi-no-styles">
      <div v-if="!store.getters.getCurrentActivity" class="buttons bvi-no-styles">
        <Control
            v-if="route.path === '/bvi/playing/' || route.path === '/bvi/playing'"
            text="Достижения"
            @clicked="()=>router.push('/bvi/workplace/')"
        />
        <Control
            v-else
            text="Опенспейс"
            @clicked="()=>router.push('/bvi/playing/')"
        />
        <Control
            text="На весь экран"
            @clicked="fullscreen()"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .interface {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: space-between;
  }
  .controls {
     .buttons {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      gap: 50px;
      margin-bottom: 25px;
    }
  }
</style>