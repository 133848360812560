<script setup>

</script>

<template>
  <section class="dialog" >
    <slot name="dialog"></slot>
    <slot name="buttons"></slot>
  </section>
</template>

<style scoped lang="scss">
.dialog {
  display: flex;
  gap: 23px;
  flex-wrap: wrap;
  align-items: stretch;
  background-color: #414349;
  color: #ffffff;
  //border: 1px solid #ffffff;
  padding: 40px;
  border-radius: 16px;
  @media (max-width: 1280px) {
    font-size: 20px;
  }
}
</style>