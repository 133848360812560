<script>
import Game from "@/game/Game";

export default {
  name: "GamePage",
  components: {
    Game
  }
};
</script>


<template>
  <Game/>
</template>

