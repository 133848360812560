const state = {
    dialogAlexGreetings: {
        title: "Алексей говорит",
        spritesheet: 'dialogDefault',
        npcImage: 'leha',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Тебя приветствую, юный падаван! Добро пожаловать в наш орден… то есть в «Компанию мечты Плюс». Меня зовут Алексей, и я буду наставником твоим.',
                buttons: [
                    {text: "Приветствую", setStatuses: [{status:'director', count: 1}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Давно хотел попасть в орден", setStatuses: [{status:'director', count: 2}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Это я буду наставником", setStatuses: [{status:'director', count: -2}], action: {name: 'setScreenAction', arg: 1}},
                    {text: "Эх, Лёха, Лёха", setStatuses: [{status:'director', count: -1}], action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            //Вопрос 2 индекс 1
            {
                dialogText: 'Мы тех ценим, кто работает много и эффективно, а также капибар уважает. Если ты капибара — замечательно, если нет, то есть к чему стремиться.',
                buttons: [
                    {text: "Я — капибара в душе", achievement: 'capibara', setStatuses: [{status:'director', count: 1}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Буду стремиться", setStatuses: [{status:'director', count: 2}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Жду первую задачу", setStatuses: [{status:'effectiveness', count: 1}], action: {name: 'setScreenAction', arg: 2}},
                    {text: "Что за чушь?", setStatuses: [{status:'director', count: -1},], action: {name: 'setScreenAction', arg: 2}},
                ]
            },
            //Вопрос 3 индекс 2
            {
                dialogText: 'Во время стажировки познаешь ты источник силы профессиональной и опыт бесценный обретёшь. Но чтобы начать свой путь, надо пройти испытания: сходить на планёрку, познакомиться с коллегами и регламентами нашими, а также выполнить одно особое задание.',
                buttons: [
                    {text: "Да прибудет со мной сила", action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            {
                dialogText: 'У каждого твоего поступка будут последствия, которые влияют на показатели — отношения с коллективом и руководителем, личную эффективность и психологическое состояние.\n\nВ конце игры тебя ждёт сюрприз от проекта «Профразвитие».',
                buttons: [
                    {text: "Всё понятно, поехали!", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}},
                ]
            },
        ]
    },

    dialogAlexFinal: {
        title: "Алексей говорит",
        spritesheet: 'dialogDefault',
        npcImage: 'leha',
        bvi_background: '/assets/images/OpenSpace/open_space.png',
        screens: [
            {
                dialogText: '— Поздравляю с завершением первого рабочего дня! Ты уже продемонстрировал интерес и энтузиазм, но многое ещё узнать тебе предстоит, мой юный падаван. Это только начало.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'В знак нашей благодарности за твою усердную работу, я хочу подарить тебе нечто особенное. Подарок будет ждать тебя на твоём рабочем столе.\n' +
                    '\n\n И помни! Многое узнать ты можешь впереди еще, мой юный падаван. Это только начало.\n',
                buttons: [
                    {text: "Забрать подарок", action: {name: 'closeDialog', arg: {goTo: 'WorkPlaceScene', deactivate: false}}},
                ]
            }
        ]
    },

    dialogPlanningUp: {
        spritesheet: 'dialogPlanningUp',
        background: 'planningUp_background',
        animations: 'dialogPlanningUp',
        bvi_background: '/assets/images/PlanningUp/planningUp_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Планёрка запланирована через 15 минут, но тебе уже прислали ссылку на онлайн встречу в «Волне». Наставник объяснил, что это внутренний сервис видеосвязи, который разработали, чтобы не допустить утечки конфиденциальной информации. Про правила использования ничего не рассказали. Как поступишь?',
                buttons: [
                    {text: 'Разберусь самостоятельно', setStatuses: [{status:'effectiveness', count: 2}],   action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Спрошу у коллеги', setStatuses: [{status:'comrades', count: 2}],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Расслаблюсь', setStatuses: [{status:'psychological', count: 2}], action: {name: 'setScreenAction', arg: 3}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Наставник упомянул, что у них есть база знаний, в которой хранится много всего полезного. Там точно должна быть инструкция к «Волне». Ты находишь эту базу и проваливаешься в статьи, ссылки, гайды. Чтение настолько увлекает, что ты немного опаздываешь на планёрку. Кажется, что никто не заметил.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Девушку, которая сидит за соседним столом, зовут Аня. Она легко откликается на твою просьбу и за пять минут показывает все важные функции «Волны» на своём компьютере. Кажется, что подготовка к созвону прошла успешно, а у тебя появилась новая знакомая в компании.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: 'Все сервисы для видеосвязи — выглядят примерно одинаково. У тебя есть 100% уверенность, что ты сможешь со всем разобраться на ходу. А в свободное время лучше посмотреть смешные видео: они помогут расслабиться и избавят от мандража в ожидании созвона.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Вопрос 2 индекс 4
            {
                dialogText: 'Планёрка идёт своим чередом. Твой наставник рассказывает команде о выполненных задачах и ставит цели на эту неделю. Вдруг ты обращаешь внимание на окошко со своим изображением: кто-то до тебя поставил на фон дерущихся мужчин в костюмах пиццы, а вместо имени — никнейм 123omnomnom. Какой ужас! Что будешь делать?',
                buttons: [
                    {text: 'Быстро отредактирую', setStatuses: [{status:'effectiveness', count: 1},{status:'psychological', count: 1},], action: {name: 'setScreenAction', arg: 5}},
                    {text: 'Классно пошучу', setStatuses: [{status:'comrades', count: 1},{status:'director', count: -1},], action: {name: 'setScreenAction', arg: 6}},
                    {text: 'Нажму на красную кнопку', setStatuses: [{status:'director', count: -1},{status:'psychological', count: -1},], action: {name: 'setScreenAction', arg: 7}},
                ]
            },
            //Ответ 1 на Вопрос 2 индекс 5
            {
                dialogText: 'Ты быстро меняешь никнейм на своё имя и указываешь должность, но из-за стресса никак не можешь найти функцию смены фона. Проходит минута, две, и ты наконец находишь нужную кнопку. Интересно, заметил ли кто-то?',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Ответ 2 на Вопрос 2 индекс 6
            {
                dialogText: 'Ты включаешь микрофон и прерываешь наставника: «Коллеги, я сегодня веду созвон со спортивного мероприятия. Кто-нибудь подскажет, где выход?»\n',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Ответ 3 на Вопрос 2 индекс 7
            {
                dialogText: 'Кто придумал добавить в интерфейс такую притягательную красную кнопку, которая включает гимн компании во время созвона? Корпоративная песня внесла хаос в созвон: сотрудники стали подпевать, а тайминги планёрки растянулись. Наставник ищет виноватого, пришлось сознаться.',
                buttons: [{text: 'Далее',  action: {name: 'setScreenAction', arg: 8}},]
            },
            //Вопрос 3 индекс 8
            {
                dialogText: 'Последний сотрудник завершает свой доклад, и тут наставник представляет тебя команде и передаёт слово. Про что расскажешь?',
                buttons: [
                    {text: 'Про стажировку мечты', setStatuses: [{status:'effectiveness', count: -1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 9}},
                    {text: 'Про учёбу', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 10}},
                    {text: 'Интересное про себя', setStatuses: [{status:'director', count: -1},{status:'psychological', count: 1},],  action: {name: 'setScreenAction', arg: 11}},
                    {text: 'Про свои задачи', achievement: 'headphones', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //Ответ 1 на Вопрос 3 индекс 9
            {
                dialogText: 'Все пять минут своего времени ты тратишь на хвалебную речь компании. Коллегам льстят твои слова, но, к сожалению, они не раскрывают тебя как профессионала.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 2 на Вопрос 3 индекс 10
            {
                dialogText: 'Ты рассказываешь, чему тебя научили в университете. Делаешь акцент на том, что хорошо знаешь теорию, но никогда не применял её на практике. Коллеги с пониманием относятся к твоим словам, ведь сами когда-то были стажёрами без опыта работы.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 3 на Вопрос 3 индекс 11
            {
                dialogText: 'Ты рассказываешь всё самое интересное про себя: про недавнее расставание, про свои навыки таролога, про умнейшую кошку Обжорку, про любимую песню «Ранеток» и про классные сериалы на ТВ-3. Про себя рассказывать приятно, но наставник испытывает стыд — не такого стажёра он брал в компанию.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Ответ 4 на Вопрос 3 индекс 12
            {
                dialogText: 'Ты стараешься почти дословно пересказать слова наставника о своих рабочих обязанностях. Коллеги слушают и задают вопросы, ты на них отвечаешь. А наставнику приятно, что ты запомнил его учение.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 13}},
                ]
            },
            //Финал индекс 13
            {
                dialogText: 'Планёрка подошла к концу. Ты научился пользоваться «Волной» и рассказал о себе коллективу. Можно браться за новые задачи.',
                buttons: [
                    {text: "Перейти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            }
        ]
    },

    dialogPrinter: {
        spritesheet: 'dialogPrinter',
        background: 'printer_background',
        animations: 'dialogPrinter',
        bvi_background: '/assets/images/Printer/printer_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'Наставник попросил тебя срочно распечатать текст своего доклада. Ты подходишь к принтеру и понимаешь, что бумаги нет. Рядом лежит нераспечатанная упаковка листов A4. Можно ли её открыть? Или лучше взять черновики — не зря же они лежат на столе рядом с принтером.',
                buttons: [
                    {text: 'Открыть пачку', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Спросить разрешения у наставника', setStatuses: [{status:'director', count: 1},{status:'effectiveness', count: -1},], action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Использовать черновик', setStatuses: [{status:'director', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Нажать на волшебную кнопку', achievement: "areiopagos", setStatuses: [{status:'effectiveness', count: -1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Ты решительно открываешь упаковку листов А4, распечатываешь текст выступления для наставника и стараешься не замечать гневного взгляда бухгалтера Насти. Ведь ты открыл пачку бумаги, которая предназначена исключительно для бухгалтерии. Но кто знал про это?',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Наставник достал для тебя листы из своего личного ящика и напомнил, что настоящий джедай использует Силу для знания и защиты, а не для атаки. Интересно, как это поможет с использованием принтера?',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: 'Ты ловко хватаешь черновики, засовываешь их в принтер и уже через несколько секунд получаешь тезисы для своего наставника. Магистр Алексей доволен, а бухгалтер Настя — нет. Это были не черновики, а её рабочие документы.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 4 на Вопрос 1 индекс 4
            {
                dialogText: 'Красивая кнопка на принтере так и манит. Интересно, что будет если её нажать? Может волшебным образом появится офис-менеджер и принесёт бумагу? Твой палец касается кнопки, принтер выключается. Всем своим видом он говорит, что магия не сработала. А из офиса раздаётся крик: «Кто выключил принтер?»',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Финал индекс 5
            {
                dialogText: 'Тебе всё-таки удалось выполнить особое поручение наставника. Теперь пора заняться остальными задачами.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
        ]
    },

    dialogConditioner: {
        spritesheet: 'dialogConditioner',
        background: 'conditioner_background',
        animations: 'dialogConditioner',
        bvi_background: '/assets/images/Conditioner/conditioner_background.png',
        screens: [
            //Вопрос 1 индекс 0
            {
                dialogText: 'В офисе душно: ты физически ощущаешь тяжесть воздуха, а одежда начинает постепенно прилипать к телу. Кондиционер не включён, окна закрыты. Кажется, что только ты замечаешь дискомфорт — остальные коллеги работают как ни в чём не бывало. Твои действия?',
                buttons: [
                    {text: 'Включу кондиционер',  setStatuses: [{status:'psychological', count: 1}, {status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 1}},
                    {text: 'Громко спрошу разрешения', achievement: 'conditioner',  setStatuses: [{status:'psychological', count: -1},{status:'comrades', count: 1},],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Продолжу работу',  setStatuses: [{status:'psychological', count: -2},],  action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Прогуляюсь возле офиса',  setStatuses: [{status:'psychological', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                ]
            },
            //Ответ 1 на Вопрос 1 индекс 1
            {
                dialogText: 'Пульт от кондиционера лежит рядом. Ты смотришь на него, он смотрит на тебя — искра, буря, безумие. Вот оно спасение! Ты хватаешь пульт и включаешь кондиционер на полную. Тебе хорошо и прохладно, а вот дизайнер Марина смотрит в твою сторону с неодобрением. Надо было спросить разрешения...\n',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 2 на Вопрос 1 индекс 2
            {
                dialogText: 'Ты громко спрашиваешь, можно ли включить кондиционер. Вроде бы никто не против. Берёшь пульт, нажимаешь на кнопку, дует приятный ветерок. Хорошо!\n',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 3 на Вопрос 1 индекс 3
            {
                dialogText: '«Настоящий профессионал обязан стойко и мужественно переносить все тяготы и лишения офисной службы», — если ты ничего не путаешь, то примерно такие слова были в уставе компании. В любом случае ты сосредотачиваешься на выполнении рабочих задач, комфорт — для слабаков.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            },
            //Ответ 4 на Вопрос 1 индекс 4
            {
                dialogText: 'Чтобы не отвлекать коллег своей просьбой, ты решаешь выйти из офиса и немного подышать свежим воздухом. Тебе хорошо, а вот наставник расстроился, что ты не предупредил.',
                buttons: [
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: true}}}
                ]
            }
        ]
    },

    dialogPapers: {
        spritesheet: 'dialogPapers',
        background: 'papers_background',
        animations: 'dialogPapers',
        bvi_background: '/assets/images/Papers/papers_background.png',
        screensBVI: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Первый день стажёра не обходится без изучения основных регламентов, инструкций и положений, принятых в организации. Часть документов удалось изучить в отделе кадров, остальное — на сладкое. Алексей напомнил, что важно изучить правила внутреннего распорядка. После он проведёт небольшую проверку по основным пунктам.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            // Вопрос 1 Экран 2 индекс 1
            {
                dialogText: 'В правилах сказано, что в организации действует пропускная система. Ты забываешь или теряешь пропуск. Как поступишь?',
                buttons: [
                    {text: 'Обратиться к коллегам', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 2}},
                    {text: 'Отвлечь наставника', achievement: 'keyCard',setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 3}},
                    {text: 'Договориться с охранником', setStatuses: [{status:'director', count: -1},{status:'effectiveness', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                    {text: 'Подождать в коридоре', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 5}},
                ]
            },
            //Ответ 1 на вопрос 1 индекс 2
            {
                dialogText: 'Ты пишешь знакомой коллеге Маше в личные сообщения, в ответ — грозный смайлик. Она сейчас на созвоне. Через пять минут Маша приходит, проводит тебя через КПП и напоминает, что надо обращать внимание на статус в мессенджере. Пусть так, зато ты добираешься до своего рабочего места',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 2 на вопрос 1 индекс 3
            {
                dialogText: 'Тебе неловко писать наставнику с такой глупой просьбой, но ты всё же решаешься на отправку сообщения. Алексей проводит тебя через КПП без лишних вопросов и объясняет, как можно восстановить пропуск. А что? Ты же его стажёр — наставник за тебя отвечает.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 3 на вопрос 1 индекс 4
            {
                dialogText: 'Договориться с охранником не вышло. Он плохо тебя знает, да и настроение у него не очень — вчера проиграла его любимая команда. Ты устраиваешь скандал, приходит наставник и пропускает тебя. Алексею стыдно, а ты теряешь рабочий настрой.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Ответ 4 на вопрос 1 индекс 5
            {
                dialogText: 'Ты начинаешь работать в коридоре и устраиваешь засаду на коллег. Твоя задача —  проскочить через турникет вместе с любым сотрудником офиса. Вот идёт потенциальная жертва, охранник отвлёкся, ты проскальзываешь мимо.\n' +
                    'Ура, ты на рабочем месте! А вот твоему наставнику сделали выговор.\n',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
                ]
            },
            //Вопрос 2 индекс 6
            {
                dialogText: 'Друг прислал смешное видео: там такой хохотач, что у тебя не получилось удержаться — и ты смеёшься в голос. И на этом моменте ты вспоминаешь, что шуметь в рабочем пространстве нельзя. Коллеги явно недовольны. Как разрядишь обстановку?',
                buttons: [
                    {text: 'Зайду в рабочий чат', setStatuses: [{status:'comrades', count: 2},], action: {name: 'setScreenAction', arg: 7}},
                    {text: 'Найду другое видео', setStatuses: [{status:'comrades', count: -2},], action: {name: 'setScreenAction', arg: 8}},
                    {text: 'Выйду на кухню', setStatuses: [{status:'psychological', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 9}},
                    {text: 'Докажу, что видео смешное', achievement: 'memolog', setStatuses: [{status:'comrades', count: 1},], action: {name: 'setScreenAction', arg: 10}},
                ]
            },
            //Ответ 1 на вопрос 2 индекс 7
            {
                dialogText: 'Ты заходишь в рабочий чат и извиняешься перед коллегами. Твоё сообщение собрало много положительных реакций. Кажется, что обстановку удалось разрядить!',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 2 на вопрос 2 индекс 8
            {
                dialogText: 'Вместо извинений ты продолжаешь искать смешные видео, но смеёшься уже про себя. Коллег разочаровало твоё равнодушие.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 3 на вопрос 2 индекс 9
            {
                dialogText: 'Ты в растерянности уходишь туда, где тебя не будет беспокоить общее осуждение. Тебе удалось расслабиться, а вот коллективу такой жест не понравился.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Ответ 3 на вопрос 2 индекс 10
            {
                dialogText: 'Ты прислал видео в рабочий чат — оно действительно смешное. Коллеги согласны, что удержаться было невозможно, поэтому прощают тебя. Но лучше в следующий раз быть сдержаннее. ',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
                ]
            },
            //Финал индекс 11
            {
                dialogText: 'Ты успешно справляешься с мини-экзаменом от наставника. Алексей доволен.',
                buttons: [
                    {text: "Пойти в опенспейс",achievement: 'capibara', action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ],
        screens: [
          // Вопрос 1 индекс 0
          {
                dialogText: 'Первый день стажёра не обходится без изучения основных регламентов, инструкций и положений, принятых в организации. Часть документов удалось изучить в отделе кадров, остальное — на сладкое. Алексей напомнил, что важно изучить правила внутреннего распорядка. После он проведёт небольшую проверку по основным пунктам.',
                buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 1}},
                ]
          },
          // Вопрос 1 Экран 2 индекс 1
          {
              dialogText: 'В правилах сказано, что в организации действует пропускная система. Ты забываешь или теряешь пропуск. Как поступишь?',
              buttons: [
                  {text: 'Обратиться к коллегам', setStatuses: [{status:'effectiveness', count: 1},{status:'comrades', count: -1},],  action: {name: 'setScreenAction', arg: 2}},
                  {text: 'Отвлечь наставника', achievement: 'keyCard',setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: 1},],  action: {name: 'setScreenAction', arg: 3}},
                  {text: 'Договориться с охранником', setStatuses: [{status:'director', count: -1},{status:'effectiveness', count: -1},],  action: {name: 'setScreenAction', arg: 4}},
                  {text: 'Подождать в коридоре', setStatuses: [{status:'effectiveness', count: 1},{status:'director', count: -1},],  action: {name: 'setScreenAction', arg: 5}},
              ]
          },
          //Ответ 1 на вопрос 1 индекс 2
          {
              dialogText: 'Ты пишешь знакомой коллеге Маше в личные сообщения, в ответ — грозный смайлик. Она сейчас на созвоне. Через пять минут Маша приходит, проводит тебя через КПП и напоминает, что надо обращать внимание на статус в мессенджере. Пусть так, зато ты добираешься до своего рабочего места',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 2 на вопрос 1 индекс 3
          {
              dialogText: 'Тебе неловко писать наставнику с такой глупой просьбой, но ты всё же решаешься на отправку сообщения. Алексей проводит тебя через КПП без лишних вопросов и объясняет, как можно восстановить пропуск. А что? Ты же его стажёр — наставник за тебя отвечает.',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 3 на вопрос 1 индекс 4
          {
              dialogText: 'Договориться с охранником не вышло. Он плохо тебя знает, да и настроение у него не очень — вчера проиграла его любимая команда. Ты устраиваешь скандал, приходит наставник и пропускает тебя. Алексею стыдно, а ты теряешь рабочий настрой.',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Ответ 4 на вопрос 1 индекс 5
          {
              dialogText: 'Ты начинаешь работать в коридоре и устраиваешь засаду на коллег. Твоя задача —  проскочить через турникет за любым сотрудником, идущим в офис. Вот идёт потенциальная жертва, охранник отвлёкся, ты проскальзываешь мимо.\n' +
                  '\n\nУра, ты на рабочем месте! А вот твоему наставнику сделали выговор.\n',
              buttons: [
                    {text: 'Далее',  action: {name: 'setScreenAction', arg: 6}},
              ]
          },
          //Вопрос 2 индекс 6
          {
              dialogText: 'Друг прислал смешное видео: там такой хохотач, что у тебя не получилось удержаться — и ты смеёшься в голос. И на этом моменте ты вспоминаешь, что шуметь в рабочем пространстве нельзя. Коллеги явно недовольны. Как разрядишь обстановку?',
              buttons: [
                  {text: 'Зайду в рабочий чат', setStatuses: [{status:'comrades', count: 2},], action: {name: 'setScreenAction', arg: 7}},
                  {text: 'Найду другое видео', setStatuses: [{status:'comrades', count: -2},], action: {name: 'setScreenAction', arg: 8}},
                  {text: 'Выйду на кухню', setStatuses: [{status:'psychological', count: 1},{status:'comrades', count: -1},], action: {name: 'setScreenAction', arg: 9}},
                  {text: 'Докажу, что видео смешное', achievement: 'memolog', setStatuses: [{status:'comrades', count: 1},], action: {name: 'setScreenAction', arg: 10}},
              ]
          },
          //Ответ 1 на вопрос 2 индекс 7
          {
              dialogText: 'Ты заходишь в рабочий чат и извиняешься перед коллегами. Твоё сообщение собрало много положительных реакций. Кажется, что обстановку удалось разрядить!',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 2 на вопрос 2 индекс 8
          {
              dialogText: 'Вместо извинений ты продолжаешь искать смешные видео, но смеёшься уже про себя. Коллег разочаровало твоё равнодушие.',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 3 на вопрос 2 индекс 9
          {
              dialogText: 'Ты в растерянности уходишь туда, где тебя не будет беспокоить общее осуждение. Тебе удалось расслабиться, а вот коллективу такой жест не понравился.',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Ответ 3 на вопрос 2 индекс 10
          {
              dialogText: 'Ты прислал видео в рабочий чат — оно действительно смешное. Коллеги согласны, что удержаться было невозможно, поэтому прощают тебя. Но лучше в следующий раз быть сдержаннее. ',
              buttons: [
                {text: 'Далее',  action: {name: 'setScreenAction', arg: 11}},
              ]
          },
          //Финал индекс 11
            {
                dialogText: 'Ты забываешь сроки согласования служебной записки и теперь не можешь получить доступ к корпоративной сети. Придётся пробежаться по офису и быстро собрать недостающие подписи.',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 12}},
                ]
            },
            //Финал индекс 12
          {
              dialogText: 'Для перемещения используй клавиатуру, если играешь с компьютера, или тачпад, если играешь со смартфона. Постарайся собрать как можно больше недостающих подписей.',
              buttons: [
                  {text: "Играть", action: {name: 'closeDialog', arg: {goTo: 'ArcadeGameScene', deactivate: true}}},
              ]
          },
        ]
    },

    dialogPapersFinish: {
        spritesheet: 'dialogPapers',
        background: 'papers_background',
        animations: 'dialogPapers',
        bvi_background: '/assets/images/Papers/papers_background.png',
        screens: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Ты успешно справляешься с мини-экзаменом от наставника. Алексей доволен.',
                buttons: [
                    {text: "Играть ещё", action: {name: 'closeDialog', arg: {goTo: 'ArcadeGameScene', deactivate: false}}},
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ]
    },

    dialogComrades: {
        spritesheet: 'dialogComrades',
        background: 'comrades_background',
        animations: 'dialogComrades',
        bvi_background: '/assets/images/Comrades/comrades_background.png',
        screensBVI: [
            {
                dialogText: 'Наставник подзывает тебя к себе, чтобы познакомить с коллегами. «Знакомьтесь ребята, это наш стажёр, — говорит Алексей, — а это Маша — занимается дизайном, Вера — продумывает воронку продаж, Григорий — руководит пулом внештатных копирайтеров, Светлана — бухгалтер, а ещё есть Настя, Кристина, Витя и Коля. Надеюсь, что всех запомнил. Так ведь?»\n',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'Классно, у тебя получилось! Главное — не забыть через пять минут всё, что удалось запомнить.',
                buttons: [
                    {text: "Вернуться в опенспейс",achievement: 'comrades', action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ],
        screens: [
            {
                dialogText: 'Наставник подзывает тебя к себе, чтобы познакомить с коллегами. «Знакомьтесь ребята, это наш стажёр, — говорит Алексей, — А это Маша — она занимается дизайном, Вера — продумывает воронку продаж, Григорий — руководит пулом внештатных копирайтеров, Светлана — бухгалтер, а ещё есть Настя, Кристина, Витя и Коля. Надеюсь, что всех запомнил. Так ведь?»\n',
                buttons: [
                    {text: "Далее", action: {name: 'setScreenAction', arg: 1}},
                ]
            },
            {
                dialogText: 'Сейчас тебе нужно будет сыграть в «Мемори» с коллегами. Переворачивай карточки, запоминай коллегу и находи похожую карточку. Игра закончится, когда найдёшь все пары. Постарайся это сделать как можно быстрее.\n',
                buttons: [
                    {text: "Играть", achievement: 'comrades', action: {name: 'closeDialog', arg: {goTo: 'MemoryGameScene', deactivate: true}}},
                ]
            }
        ]
    },

    dialogComradesFinish: {
        spritesheet: 'dialogComrades',
        background: 'comrades_background',
        animations: 'dialogComrades',
        bvi_background: '/assets/images/Comrades/comrades_background.png',
        screens: [
            // Вопрос 1 индекс 0
            {
                dialogText: 'Классно, у тебя получилось! Главное — не забыть через пять минут всё, что удалось запомнить.',
                buttons: [
                    {text: "Играть ещё", action: {name: 'closeDialog', arg: {goTo: 'MemoryGameScene', deactivate: false}}},
                    {text: "Пойти в опенспейс", action: {name: 'closeDialog', arg: {goTo: 'OpenSpaceScene', deactivate: false}}}
                ]
            },
        ]
    },
};

const getters = {
    getDialog: state => dialog =>  {
        return state[dialog];
    },
    getScreen: state => (dialog, index, bvi=false) => {
        console.log(state[dialog]);
        return bvi && dialog.screensBVI ?
            dialog.screensBVI[index] :
            dialog.screens[index];
    }
};

export default {
    state,
    getters
};
