<script setup>
import {ref} from 'vue'
import authService from "../../plugins/auth";

// Variables: -----------------------------------------------
let showMessage = ref(false)
let environment = ref(process.env.NODE_ENV)
const share_menu = ref(false);
const link= ref(window.location.href)

// Functions: -----------------------------------------------
function login() {
  authService.login();
}

function share() {
  window.navigator.clipboard.writeText(window.location.href)
  share_menu.value = false
  showMessage.value = true

  setTimeout(() => {
    showMessage.value = false
  }, 2000)

}


</script>

<template>
  <main class="main">
    <section class="container">
      <div class="content">
        <div class="content-top">
          <div class="logo-container">
            <a href="https://intern.rsv.ru/" class="logo" target="_blank" title="intern.rsv.ru"/>
            <a href="https://rsv.ru/internships/ " target="_blank" title="rsv.ru" class="info"/>
          </div>
          <div class="share-game-container">
            <p v-if="showMessage" class="copied-message">Скопировано в&nbsp;буфер&nbsp;обмена</p>
            <button class="button-share-game" @click="()=>{share_menu=!share_menu}"></button>
            <div :class="share_menu ? 'active' : ''" class="share-menu">
              <a :href="`https://vk.com/share.php?comment=Хочешь получить первый опыт работы, но боишься сделать что-то не так? Тогда залетай в игру «Однажды в офисе» от проекта «Профразвитие». Здесь ты сможешь прожить один день из жизни стажера и узнать, с какими вызовами придется столкнуться на реальном рабочем месте. В конце игры тебя ждут призы от проекта «Профразвитие» и партнеров, а еще возможность найти стажировку мечты!
&url=${link}`" target="_blank" @click="share()" class="share-menu__button button-share-game" title="Поделиться в VK">
                <img src="/assets/images/UI/icons/vk.svg" alt="VK">
              </a>
              <a :href="`https://t.me/share/url?text=Хочешь получить первый опыт работы, но боишься сделать что-то не так? Тогда залетай в игру «Однажды в офисе» от проекта «Профразвитие». Здесь ты сможешь прожить один день из жизни стажера и узнать, с какими вызовами придется столкнуться на реальном рабочем месте. В конце игры тебя ждут призы от проекта «Профразвитие» и партнеров, а еще возможность найти стажировку мечты!&url=${link}`" target="_blank" @click="share()" class="share-menu__button button-share-game" title="Поделиться в Telegram">
                <img src="/assets/images/UI/icons/telegram.svg" alt="Telegram">
              </a>
              <button class="share-menu__button button-share-game" title="Копировать ссылку" @click="share()">
                <img src="/assets/images/UI/icons/copy.svg" alt="Копировать ссылку">
              </button>
            </div>
          </div>
        </div>
        <div class="content-centre">
          <div class="content-block__heading">
            <p>[ ИНТЕРАКТИВНАЯ ИГРА ПРО СТАЖИРОВКУ В&nbsp;КЛАССНОЙ КОМПАНИИ. ОСНОВАНА&nbsp;НА&nbsp;РЕАЛЬНЫХ ИСТОРИЯХ, КОТОРЫЕ НАМ РАССКАЗАЛИ СТАЖЁРЫ. ]</p>
            <h1 class="h1">ОДНАЖДЫ В&nbsp;ОФИСЕ</h1>
          </div>
          <div class="content-block__text">
            <p>Жизнь прекрасна. Ты&nbsp;ещё учишься в&nbsp;любимом университете но&nbsp;уже начал искать будущее место работы. В&nbsp;компаниях, которые тебе&nbsp;нравятся, либо нет вакансий, либо&nbsp;требуется большой опыт, либо в&nbsp;офисе экономят на печеньках. О, ужас!</p>
            <p>И&nbsp;вот ты&nbsp;уже отчаялся найти работу мечты, как твой приятель со&nbsp;старшего курса рассказал про проект &laquo;Профразвитие&raquo;, который даёт возможность попробовать свои силы на&nbsp;реальном рынке труда.</p>
          </div>
        </div>
        <div class="content-bottom">
          <div class="button-container">
            <button v-if="environment === 'production'" class="button button-authorization" @click="login()">ИГРАТЬ</button>
            <router-link v-else class="button button-authorization" to="/drum">НА ИГРУ</router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>

.share-menu {
  position: absolute;
  display: flex;
  z-index: 5;
  top: 0%;
  right: 0%;
  width: 90px;
  height: 90px;
  @media (max-width: 1440px) {
    width: 64px;
    height: 64px;
  }
  @media (max-width: 895px) {
    width: 42px;
    height: 42px;
  }
  &__button {
    position: absolute;
    width: 80%;
    height: 80%;
    left: 10%;
    bottom: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    img {
      width: 70%;
    }
  }
  &.active {
    .share-menu__button {
      opacity: 1;
      &:nth-child(1) {
        left: -120%;
        bottom: 5%;
      }
      &:nth-child(2) {
        left: -80%;
        bottom: -80%;
      }
      &:nth-child(3) {
        left: 2%;
        bottom: -117%;
      }
    }
  }
}

.main {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh;
  min-height: 1000px;

  @media (max-width: 1680px) {
    min-height: 730px;
  }
  @media (max-width: 1440px) {
    min-height: 705px;
  }
  @media (max-width: 1024px) {
    min-height: 770px;
  }
  @media (max-width: 895px) {
    min-height: 680px;
    padding: 25px 0;
  }
  @media (max-width: 768px) and (max-height: 425px), (max-height: 425px) {
    height: 100%;
    align-items: inherit;
    min-height: 520px;
  }
  /*@media (max-height: 810px) {
    height: 100%;
    align-items: inherit;
  }
  @media (max-height: 425px) {
    min-height: 520px;
  }*/

  // Кружка
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 330px;
    height: 725px;
    background: url("~@/assets/images/cup.png") no-repeat 0 100%/contain;
    z-index: 5;

    @media (max-width: 1680px) {
      width: 250px;
      height: 480px;
    }
    @media (max-width: 1440px) {
      width: 200px;
      height: 435px;
    }
    @media (max-width: 895px) {
      display: none;
    }
  }

  .container {
    height: auto;
  }
}

.content {

  &-top {
    display: flex;
    justify-content: space-between;
    min-height: 145px;
    background: var(--color-blue);

    @media (max-width: 1680px) {
      min-height: 95px;
    }
    @media (max-width: 895px) {
      min-height: 65px;
      background: none;
    }

    .logo-container {
      position: relative;
      display: grid;
      grid-template-columns: 265px 140px;
      grid-template-rows: 100%;
      grid-gap: 60px;
      width: 605px;
      background: var(--bg-main);

      @media (max-width: 1680px) {
        grid-template-columns: 200px 110px;
        grid-gap: 45px;
        width: 510px;
      }
      @media (max-width: 1024px) {
        grid-template-columns: 180px 110px;
        grid-gap: 10px;
        width: 441px;
      }
      @media (max-width: 895px) {
        grid-template-columns: 135px 110px;
      }
      @media (max-width: 640px) {
        display: block;
        width: 195px;
      }

      /* Часть фона. */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -1px;
        width: 166px;
        height: calc(100% + 2px);
        background: url("~@/assets/images/top_left.svg") no-repeat 100% 0/auto 100%;

        @media (max-width: 895px) {
          display: none;
        }
      }

      /* Кот. */
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 550px;
        height: 410px;
        background: url("~@/assets/images/cat.png") no-repeat 100% 0/contain;
        transform: translate(115%, 50%);
        z-index: 5;

        @media (max-width: 1680px) {
          width: 380px;
          height: 200px;
          transform: translate(100%, 25%);
        }
        @media (max-width: 1440px) {
          width: 300px;
          height: 150px;
          transform: translate(100%, 0);
        }
        @media (max-width: 895px) {
          right: auto;
          width: 245px;
          height: 135px;
          left: 0;
          transform: rotate(-25deg) translate(110%, 110%);
        }
        @media (max-width: 640px) {
          transform: rotate(-25deg) translate(0, 80%);
        }
        @media (max-width: 340px) {
          width: 200px;
          height: 100px;
        }

      }

      .logo {
        position: relative;
        height: 83px;
        background: url("~@/assets/images/logo.svg") no-repeat 0 50%/contain;

        @media (max-width: 1440px) {
          height: 60px;
        }
        @media (max-width: 1024px) {
          height: 50px;
        }
        @media (max-width: 895px) {
          height: 43px;
        }

        // Line
        &::after {
          content: "";
          position: absolute;
          right: -38px;
          top: 15%;
          width: 1px;
          height: 85%;
          background: white;

          @media (max-width: 16801px) {
            right: -25px;
            height: 75%;
          }
          @media (max-width: 1295px) {
            display: none;
          }
        }
      }

      .info {
        width: 170px;
        height: 90px;
        background: url("~@/assets/images/logo_rsv.svg") no-repeat 100% 50%/contain;

        @media (max-width: 1440px) {
          height: 60px;
        }
        @media (max-width: 895px) {
          height: 50px;
        }
        @media (max-width: 640px) {
          display: none;
        }
      }

    }

    .share-game-container {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 160px;
      height: 100%;
      background: var(--bg-main);

      @media (max-width: 1440px) {
        width: 140px;
      }
      @media (max-width: 895px) {
        width: 90px;
      }

      /* Часть фона. */
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -0.015rem;
        width: 100%;
        height: 165px;
        background: var(--bg-main) url("~@/assets/images/bg/top-right.svg") no-repeat 100% 0/auto 101%;
        z-index: 1;

        @media (max-width: 1440px) {
          height: 145px;
        }
        @media (max-width: 895px) {
          display: none;

        }

      }

      .copied-message {
        position: absolute;
        top: 25px;
        right: 140px;
        background: white;
        border-radius: 60px;
        padding: 15px 20px;
        @extend %font-inter-500;
        color: var(--color-blue);
        font-size: 18px;
        text-transform: uppercase;
        transform: scale(0);
        opacity: 0;
        z-index: 100;

        @media (max-width: 895px) {
          right: 50px;
          top: -8px;
          padding: 12px 18px;
          font-size: 14px;
        }
        @media (max-width: 425px) {
          top: 2px;
          padding: 8px 14px;
          font-size: 10px;
        }

        animation: showMessage .2s ease-in-out forwards alternate;
        @keyframes showMessage {
          0% {
            opacity: 0;
            transform: scale(0);
          }
          95% {
            opacity: 1;
            transform: scale(1.05);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }

  }

  &-centre {
    position: relative;
    display: grid;
    grid-template-columns: minmax(800px, 59%) auto;
    grid-template-rows: auto;
    grid-gap: 50px;
    justify-content: space-between;
    height: min-content;
    background: var(--color-blue);
    border-radius: 73px 0 73px 0;
    padding: 125px 80px 105px 80px;

    @media (max-width: 1680px) {
      grid-template-columns: 655px auto;
      grid-gap: 30px;
      padding: 60px 45px 45px 45px;
    }
    @media (max-width: 1440px) {
      grid-template-columns: 490px auto;
    }
    @media (max-width: 1024px) {
      grid-template-columns: auto;
    }
    @media (max-width: 895px) {
      border-radius: 30px 30px 30px 0;
      padding: 110px 20px 60px 20px;
    }
    @media (max-width: 768px) {
      grid-gap: 20px;
    }
    @media (max-width: 425px) {
      height: 100%;
    }

    .content-block__heading {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
      }
      @media (max-width: 768px) {
        gap: 15px;
      }

      p {
        max-width: 740px;
        @extend %font-inter-500;
        font-size: 18px;

        @media (max-width: 1680px) {
          font-size: 16px;
        }
        @media (max-width: 1440px) {
          font-size: 12px;
        }
        @media (max-width: 425px) {
          font-size: 10px;
        }
      }

      .h1 {
        @extend %font-unbounded-400;
        font-size: 118px;
        line-height: 1;

        @media (max-width: 1840px) {
          font-size: 110px;
        }
        @media (max-width: 1680px) {
          font-size: 80px;
        }
        @media (max-width: 1440px) {
          font-size: 65px;
        }
        @media (max-width: 895px) {
          font-size: 50px;
          margin-bottom: 15px;
        }
        @media (max-width: 425px) {
          font-size: 40px;

        }
        @media (max-width: 340px) {
          font-size: 32px;
        }

      }
    }

    .content-block__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(-1%, -4%);
      z-index: 1;

      p {
        @extend %font-inter-400;
        font-size: 24px;
        line-height: 1.28;
        padding-top: 4px;

        &:not(:last-child) {
          margin-bottom: 25px;

          @media (max-width: 1024px) {
            margin-bottom: 15px;
          }
          @media (max-width: 425px) {
            margin-bottom: 8px;
          }
        }

        @media (max-width: 1680px) {
          font-size: 20px;
        }
        @media (max-width: 1440px) {
          font-size: 16px;
        }
        @media (max-width: 425px),
        screen and (orientation: landscape) and (max-width: 768px) {
          font-size: 12px;
          line-height: 1.15;
        }

      }

    }
  }

  &-bottom {
    position: relative;
    display: flex;
    justify-content: flex-end;
    height: 130px;
    background: var(--color-blue);
    border-radius: 0 0 73px 73px;

    @media (max-width: 1440px) {
      height: 100px;
    }
    @media (max-width: 895px) {
      height: 55px;
      border-radius: 0 0 30px 30px;
    }

    // Плашка "Сезон 1"
    &::before {
      content: "СЕЗОН 1";
      position: absolute;
      right: 50%;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 45px;
      border-radius: 100px;
      background: #252525;
      @extend %font-unbounded-400;
      white-space: nowrap;
      font-size: 55px;
      transform: rotate(-4deg) translate(0, -85%);
      z-index: 15;

      @media (max-width: 1680px) {
        font-size: 34px;
        transform: rotate(-4deg) translate(-5%, -85%);
      }
      @media (max-width: 1440px) {
        padding: 15px 30px;
        font-size: 28px;
        transform: rotate(-4deg) translate(-35%, -85%);
      }
      @media (max-width: 1024px) {
        padding: 10px 15px;
        font-size: 18px;
        transform: rotate(-12deg) translate(35%, -120%);
      }
      @media (max-width: 895px) {
        padding: 0 5px;
        font-size: 18px;
        bottom: 65px;
        right: auto;
        left: 65px;
        transform: rotate(-15deg) translate(0);
      }
      @media (max-width: 340px) {
        left: 15px;
        bottom: 53px;
        font-size: 16px;
      }

    }

    // Птичка
    &::after {
      content: "";
      position: absolute;
      left: 45%;
      bottom: 0;
      width: 130px;
      height: 125px;
      background: url("~@/assets/images/bird.png") no-repeat 50% 50%/contain;
      z-index: 15;
      transform: translateY(8px);

      @media (max-width: 1440px) {
        height: 90px;
        left: 39%;
      }
      @media (max-width: 1024px) {
        height: 70px;
        width: 70px;
      }
      @media (max-width: 895px) {
        left: 10%;
      }
      @media (max-width: 340px) {
        width: 50px;
        height: 50px;
        transform: translateY(0);
      }
    }

    .button-container {
      position: relative;
      width: 46%;
      min-width: 225px;
      height: 100%;
      padding: 30px 0 30px 115px;
      background: var(--bg-main);

      @media (max-width: 1440px) {
        width: 40%;
        padding: 30px 0 30px 90px;
      }
      @media (max-width: 895px) {
        width: 77%;
        padding: 12px 0 12px 55px;
      }

      // Часть фона.
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 160px;
        height: 100%;
        background: url("~@/assets/images/bg/bottom-right.svg") no-repeat 100% 0/100% auto;
        z-index: 11;

        @media (max-width: 1440px) {
          width: 122px;
          background-size: 102% 100%;
        }
        @media (max-width: 895px) {
          width: 68px;
        }
      }

      .button {
        width: 100%;
        justify-content: space-between;
      }

    }

  }
}

</style>
