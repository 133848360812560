<script setup>
import {defineProps, defineEmits} from 'vue';
import speak from "../../../../plugins/speak";

const emit = defineEmits(['clicked'])

const props = defineProps({
  text: {
    type: String,
    required: true,
  }
})

const clicked = () => {
  emit('clicked');
}

</script>

<template>
  <div class="control-wrap" @mouseover="speak">
    <a
        href="#"
        :title="props.text.replace(/<br>/g, '')"
        class="control"
        @click.prevent="clicked()"
    >
      <span v-html="props.text" class="bvi-no-styles"></span>
    </a>
  </div>
</template>

<style scoped lang="scss">
  .control {
    display: inline-block;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    padding: 25px 35px;
    border-radius: 16px;
    background-color: #FFF;
    color: #000;
    transition: all 0.3s ease-in-out;
    &-wrap {
      border-radius: 16px !important;
    }
    @media (max-width: 1280px) {
      font-size: 20px;
    }
    &:hover {
      background-color: #F15A29;
      color: #FFF;
    }
  }
</style>