<script setup>
import authService from "../../../plugins/auth";
import {ref} from "vue";
import speak from "../../../plugins/speak";

const link= ref(window.location.href);
const showMessage = ref(false);
let environment = ref(process.env.NODE_ENV);

function login() {
  authService.login();
}

function share() {
  window.navigator.clipboard.writeText(window.location.href)
  showMessage.value = true

  setTimeout(() => {
    showMessage.value = false
  }, 2000)

}
</script>

<template>
  <main class="main">
    <div class="content-top container bvi-no-styles">
      <div class="logo-container bvi-no-styles">
        <a href="https://intern.rsv.ru/" target="_blank" title="intern.rsv.ru" class="logo bvi-no-styles"/>
        <a href="https://rsv.ru/internships/" target="_blank" title="rsv.ru" class="info bvi-no-styles"/>
      </div>
      <div class="share-menu bvi-no-styles">
        <a :href="`https://vk.com/share.php?comment=Хочешь получить первый опыт работы, но боишься сделать что-то не так? Тогда залетай в игру «Однажды в офисе» от проекта «Профразвитие». Здесь ты сможешь прожить один день из жизни стажера и узнать, с какими вызовами придется столкнуться на реальном рабочем месте. В конце игры тебя ждут призы от проекта «Профразвитие» и партнеров, а еще возможность найти стажировку мечты!
&url=${link}`" target="_blank" @click="share()" class="bvi-no-styles share-menu__button" title="Поделиться в VK">
          <img src="/assets/images/UI/icons/vk.png" class="bvi-no-styles" alt="VK">
        </a>
        <a :href="`https://t.me/share/url?text=Хочешь получить первый опыт работы, но боишься сделать что-то не так? Тогда залетай в игру «Однажды в офисе» от проекта «Профразвитие». Здесь ты сможешь прожить один день из жизни стажера и узнать, с какими вызовами придется столкнуться на реальном рабочем месте. В конце игры тебя ждут призы от проекта «Профразвитие» и партнеров, а еще возможность найти стажировку мечты!&url=${link}`" target="_blank" @click="share()" class="bvi-no-styles share-menu__button" title="Поделиться в Telegram">
          <img src="/assets/images/UI/icons/telegram.png" class="bvi-no-styles" alt="Telegram">
        </a>
        <button class="bvi-no-styles share-menu__button" title="Копировать ссылку" @click="share()">
          <img src="/assets/images/UI/icons/copy.png" class="bvi-no-styles" alt="Копировать ссылку">
        </button>
      </div>
    </div>
    <section class="container">
      <div class="content">
          <p v-if="showMessage" class="copied-message" @mouseover="speak">Скопировано в&nbsp;буфер&nbsp;обмена</p>
        </div>
        <div class="content-centre border">
          <div class="content-block__heading">
            <p @mouseover="speak">[ ИНТЕРАКТИВНАЯ ИГРА ПРО СТАЖИРОВКУ В&nbsp;КЛАССНОЙ КОМПАНИИ. ОСНОВАНА&nbsp;НА&nbsp;РЕАЛЬНЫХ ИСТОРИЯХ, КОТОРЫЕ НАМ РАССКАЗАЛИ СТАЖЁРЫ. ]</p>
            <h1 class="h1" @mouseover="speak">ОДНАЖДЫ В&nbsp;ОФИСЕ</h1>
            <p class="season-label border" @mouseover="speak">
              СЕЗОН 1
            </p>
          </div>
          <div class="content-block__text">
            <p @mouseover="speak">Жизнь прекрасна. Ты&nbsp;ещё учишься в&nbsp;любимом университете но&nbsp;уже начал искать будущее место работы. В&nbsp;компаниях, которые тебе&nbsp;нравятся, либо нет вакансий, либо&nbsp;требуется большой опыт, либо в&nbsp;офисе экономят на печеньках. О, ужас!</p>
            <p @mouseover="speak">И&nbsp;вот ты&nbsp;уже отчаялся найти работу мечты, как твой приятель со&nbsp;старшего курса рассказал про проект &laquo;Профразвитие&raquo;, который даёт возможность попробовать свои силы на&nbsp;реальном рынке труда.</p>
            <div class="button-container">
              <button v-if="environment === 'production'" class="button button-authorization bvi-no-styles" @click="login()" @mouseover="speak">ИГРАТЬ</button>
              <router-link v-else class="button button-authorization bvi-no-styles" to="/drum/" @mouseover="speak">НА ИГРУ</router-link>
            </div>
          </div>
        </div>
    </section>
  </main>
</template>

<style scoped lang="scss">
.main {
  position: relative;
  height: 100vh;
  min-height: 1000px;

  @media (max-width: 1680px) {
    min-height: 730px;
  }
  @media (max-width: 1440px) {
    min-height: 705px;
  }
  @media (max-width: 1024px) {
    min-height: 770px;
  }
  @media (max-width: 895px) {
    min-height: 680px;
    padding: 25px 0;
  }
  @media (max-width: 768px) and (max-height: 425px), (max-height: 425px) {
    height: 100%;
    align-items: inherit;
    min-height: 520px;
  }
  /*@media (max-height: 810px) {
    height: 100%;
    align-items: inherit;
  }
  @media (max-height: 425px) {
    min-height: 520px;
  }*/

  .container {
    height: auto;
  }
}

.share-menu {
  display: flex;
  align-items: center;
  gap: 10px;
  &__button {
    width: 70px;
    @media (max-width: 1440px) {
      width: 64px;
      height: 64px;
    }
    @media (max-width: 895px) {
      width: 42px;
      height: 42px;
    }
    img {
      width: 100%;
    }
  }
}


.content {
  .copied-message {
    position: absolute;
    top: 25px;
    right: 140px;
    background: white;
    border-radius: 60px;
    padding: 15px 20px;
    @extend %font-inter-500;
    color: var(--color-blue);
    font-size: 18px;
    text-transform: uppercase;
    transform: scale(0);
    opacity: 0;
    z-index: 100;

    @media (max-width: 895px) {
      right: 50px;
      top: -8px;
      padding: 12px 18px;
      font-size: 14px;
    }
    @media (max-width: 425px) {
      top: 2px;
      padding: 8px 14px;
      font-size: 10px;
    }

    animation: showMessage .2s ease-in-out forwards alternate;
    @keyframes showMessage {
      0% {
        opacity: 0;
        transform: scale(0);
      }
      95% {
        opacity: 1;
        transform: scale(1.05);
      }
      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
  &-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 46px;
    padding: 20px;
    background: var(--bg-main);
    @media (max-width: 1680px) {
      min-height: 95px;
    }
    @media (max-width: 895px) {
      min-height: 65px;
    }

    .logo-container {
      position: relative;
      display: grid;
      grid-template-columns: 265px 140px;
      grid-template-rows: 100%;
      grid-gap: 60px;
      width: 605px;


      @media (max-width: 1680px) {
        grid-template-columns: 200px 110px;
        grid-gap: 45px;
        width: 450px;
      }
      @media (max-width: 1024px) {
        grid-template-columns: 180px 110px;
        grid-gap: 10px;
        width: 380px;
      }
      @media (max-width: 895px) {
        grid-template-columns: 135px 110px;
      }
      @media (max-width: 640px) {
        display: block;
        width: 195px;
      }

      .logo {
        position: relative;
        height: 83px;
        background: url("~@/assets/images/logo.svg") no-repeat 0 50%/contain;

        @media (max-width: 1440px) {
          height: 60px;
        }
        @media (max-width: 1024px) {
          height: 50px;
        }
        @media (max-width: 895px) {
          height: 43px;
        }

        // Line
        &::after {
          content: "";
          position: absolute;
          right: -38px;
          top: 15%;
          width: 1px;
          height: 85%;
          background: white;

          @media (max-width: 16801px) {
            right: -25px;
            height: 75%;
          }
          @media (max-width: 1295px) {
            display: none;
          }
        }
      }

      .info {
        height: 95px;
        background: url("~@/assets/images/logo-info.png") no-repeat 100% 50%/contain;

        @media (max-width: 1440px) {
          height: 60px;
        }
        @media (max-width: 895px) {
          height: 50px;
        }
        @media (max-width: 640px) {
          display: none;
        }
      }

    }

    .share-game-container {
      position: relative;
      display: flex;
      justify-content: flex-end;
      width: 160px;
      height: 100%;
      background: var(--bg-main);

      @media (max-width: 1440px) {
        width: 140px;
      }
      @media (max-width: 895px) {
        width: 90px;
      }

      .copied-message {
        position: absolute;
        top: 25px;
        right: 140px;
        background: white;
        border-radius: 60px;
        padding: 15px 20px;
        @extend %font-inter-500;
        color: var(--color-blue);
        font-size: 18px;
        text-transform: uppercase;
        transform: scale(0);
        opacity: 0;
        z-index: 100;

        @media (max-width: 895px) {
          right: 50px;
          top: -8px;
          padding: 12px 18px;
          font-size: 14px;
        }
        @media (max-width: 425px) {
          top: 2px;
          padding: 8px 14px;
          font-size: 10px;
        }

        animation: showMessage .2s ease-in-out forwards alternate;
        @keyframes showMessage {
          0% {
            opacity: 0;
            transform: scale(0);
          }
          95% {
            opacity: 1;
            transform: scale(1.05);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }

  }

  &-centre {
    position: relative;
    display: grid;
    grid-template-columns: minmax(800px, 59%) auto;
    grid-template-rows: auto;
    grid-gap: 50px;
    justify-content: space-between;
    height: min-content;
    background: var(--color-blue);
    border-radius: 73px;
    padding: 80px;

    @media (max-width: 1680px) {
      grid-template-columns: 655px auto;
      grid-gap: 30px;
      padding: 60px 45px 45px 45px;
    }
    @media (max-width: 1440px) {
      grid-template-columns: 490px auto;
    }
    @media (max-width: 1024px) {
      grid-template-columns: auto;
    }
    @media (max-width: 895px) {
      border-radius: 30px;
      padding: 40px 20px;
    }
    @media (max-width: 768px) {
      grid-gap: 20px;
    }
    @media (max-width: 425px) {
      height: 100%;
    }

    .content-block__heading {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 100%;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
      }
      @media (max-width: 768px) {
        gap: 15px;
      }

      p {
        max-width: 740px;
        @extend %font-inter-500;
        font-size: 18px;

        @media (max-width: 1680px) {
          font-size: 16px;
        }
        @media (max-width: 1440px) {
          font-size: 12px;
        }
        @media (max-width: 425px) {
          font-size: 10px;
        }
        &.season-label {
          font-size: 55px;
          background-color: #56BBFF;
          text-align: center;
          max-width: fit-content;
          padding: 10px 50px;
          border-radius: 76px;
          @media (max-width: 1840px) {
            font-size: 110px;
          }
          @media (max-width: 1680px) {
            font-size: 30px;
          }
        }
      }

      .h1 {
        @extend %font-unbounded-400;
        font-size: 118px;
        line-height: 1;
        flex-grow: 1;
        @media (max-width: 1840px) {
          font-size: 110px;
        }
        @media (max-width: 1680px) {
          font-size: 80px;
        }
        @media (max-width: 1440px) {
          font-size: 65px;
        }
        @media (max-width: 895px) {
          font-size: 50px;
          margin-bottom: 15px;
        }
        @media (max-width: 425px) {
          font-size: 40px;

        }
        @media (max-width: 340px) {
          font-size: 32px;
        }

      }
    }

    .content-block__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: translate(-1%, -4%);
      z-index: 1;

      p {
        @extend %font-inter-400;
        font-size: 24px;
        line-height: 1.28;
        padding-top: 4px;

        &:not(:last-child) {
          margin-bottom: 25px;

          @media (max-width: 1024px) {
            margin-bottom: 15px;
          }
          @media (max-width: 425px) {
            margin-bottom: 8px;
          }
        }

        @media (max-width: 1680px) {
          font-size: 20px;
        }
        @media (max-width: 1440px) {
          font-size: 16px;
        }
        @media (max-width: 425px),
        screen and (orientation: landscape) and (max-width: 768px) {
          font-size: 12px;
          line-height: 1.15;
        }

      }

    }
  }
  .button-container {
    position: relative;
    min-width: 225px;
    height: 100%;
    margin-top: 40px;

    .button {
      width: 100%;
      justify-content: space-between;
    }

  }
}
</style>