import store from "@/store";
// import {Math} from 'phaser'
export default class Achievements {
    constructor(scene) {
        this.scene = scene;
        this.achievement = false;
    }

    addAchievement(achievement) {
        const achievements = store.getters.getAchievements;
        if (!achievements.includes(achievements)) {
            this.achievement = this.scene.add.sprite(718,-109, 'achievement').setOrigin(0,0).setAlpha(0)
            store.dispatch('addAchievementAction', achievement);
            this.scene.tweens.add({
                targets: this.achievement,
                y: 116,
                alpha: 1,
                duration: 800,
                ease: 'Linear'
            });

            setTimeout(()=>{
                this.achievement.destroy();
            }, 2500)
        }
    }
}