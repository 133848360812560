<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { ref, onMounted, provide } from 'vue';
import authService from "../../plugins/auth";
import gsap from "gsap";
import { v4 as uuidv4 } from "uuid";

// Components: ----------------------------------------------
import Drum from "@/components/drum";

// Route ----------------------------------------------------
const router = useRouter();

// Store: State/Getters/Actions -----------------------------
const store = useStore()

// Variables: -----------------------------------------------
let drum_show        = ref(false)
let drum_rotate      = ref(false)
let show_button_next = ref(false)
let isLoading        = ref(true)
let drumRef          = ref()
let userId           = ref();
let showRotateButton = ref(false);

// Functions: -----------------------------------------------
const rotationDrum = () => drumRef.value.rotationDrum()

// Hooks: ---------------------------------------------------

onMounted(async () => {

  if (process.env.NODE_ENV == 'production') {
    await authService.getProfile();
    userId.value  = await store.dispatch('getUserId');
  } else {
    userId.value = !localStorage.getItem('userId') ? uuidv4() : localStorage.getItem('userId');
  }

  await store.dispatch('loadGameStatusAction', userId.value);
  if (!store.getters.getNeedGreetings) {
    router.push({ path: "/playing/" })
  } else {
    store.dispatch('setUserIdAction', userId.value)
    isLoading.value  = false
    const animations = () => {
      // Default state:
      gsap.set([ "span[data-heading-word='1']", "span[data-heading-word='2']", "span[data-heading-word='3']" ], { yPercent: 110 });
      gsap.set([ "img[data-emoticon='1']", "img[data-emoticon='2']", "img[data-emoticon='3']", "img[data-emoticon='4']" ], { yPercent: 50, opacity: 0 });
      gsap.set(".button-rotate", { opacity: 0 });

      // Start all animations:
      let tl = gsap.timeline()

      tl.to(".heading span[data-heading-word='1']", { yPercent: 0, duration: .5, delay: 1 })
      tl.to(".heading span[data-heading-word='2']", { yPercent: 0, duration: .5 }, "<50%")
      tl.to(".heading span[data-heading-word='3']", { yPercent: 0, duration: .5 }, "<50%")

      // - start animation - show emoticons:
      tl.to("img[data-emoticon='1']", { yPercent: 0, duration: .5, opacity: 1, delay: .5 }, "<")
      tl.to("img[data-emoticon='2']", { yPercent: 0, duration: .5, opacity: 1 }, "<30%")
      tl.to("img[data-emoticon='3']", { yPercent: 0, duration: .5, opacity: 1 }, "<30%")
      tl.to("img[data-emoticon='4']", { yPercent: 0, duration: .5, opacity: 1 }, "<30%")

      // - start animation - hide emoticons:
      tl.to("img[data-emoticon='1']", { yPercent: 0, duration: .5, opacity: 0, delay: 1 })
      tl.to("img[data-emoticon='2']", { yPercent: 0, duration: .5, opacity: 0 }, "<30%")
      tl.to("img[data-emoticon='3']", { yPercent: 0, duration: .5, opacity: 0 }, "<30%")
      tl.to("img[data-emoticon='4']", { yPercent: 0, duration: .5, opacity: 0 }, "<30%")

      // - show drum:
      tl.to(".emoticons", { maxHeight: 0, overflow: "hidden", onComplete: () => {
          drum_show.value = true
        }}, "<")
      tl.to(".button-rotate", {
        opacity: 1, zIndex: 1, duration: .5
      })

    }
    animations();
  }


})

provide('showBtnNextPage', (value) => {
  show_button_next.value = value
});
</script>

<template>
  <main class="main">
    <section class="container">
      <div :style="isLoading ? 'opacity: 0' : 'opacity: 1' " class="content">

        <div class="heading-container">
          <h2 class="h2 heading">
            <span v-for="(word, word_i) in ['КУДА&nbsp;', 'ОТПРАВИШЬСЯ', 'СТАЖИРОВАТЬСЯ?']" :key="word_i">
              <span :data-heading-word="word_i + 1" v-html="word"/>
            </span>
          </h2>
          <button v-if="!drum_rotate" :class="showRotateButton ? 'active' : ''" class="button-rotate" @click="[rotationDrum(), drum_rotate= true]">ВРАЩАТЬ БАРАБАН!</button>
        </div>

        <div class="emoticons">
          <img v-for="i in 4" :key="i" :data-emoticon="i" :src="require(`@/assets/images/emoticons.png`)" alt="куда отправишься стажироваться?">
        </div>

        <p v-if="show_button_next" class="desc">На&nbsp;сайте &laquo;Профразвития&raquo; ты&nbsp;выбрал интересную стажировку, заполнил анкету и&nbsp;прошёл оценку компетенций. Спустя несколько дней, о&nbsp;чудо, выполнил тестовое задание и&nbsp;получил приглашение на&nbsp;собеседование, которое ты&nbsp;с&nbsp;блеском прошёл! Теперь дело за&nbsp;малым&nbsp;&mdash; пройти стажировку мечты.</p>

        <Drum v-if="drum_show" @ready="()=>{ showRotateButton = true }" ref="drumRef"/>

        <router-link v-if="show_button_next" to="/company" class="button">Далее</router-link>

      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>

.main {
  height: 100vh;
  overflow: hidden;
  min-height: 800px;

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    min-height: initial;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0;

  @media (max-width: 1024px) {
    padding: 60px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    padding: 20px 0;
  }

}

.button-rotate {
  transform: scale(0) translateY(110%);
  z-index: -1;
  transform-origin: center;
  &.active {
    transform: scale(1) translateY(110%);
  }
}

.heading-container {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 15px;
  }

  .h2 {
    text-align: center;

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      max-width: 450px;
    }

    span {
      display: inline-block;
      overflow: hidden;
    }
  }

}

.emoticons {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 620px;
  height: 50vh;

  @media (max-height: 425px) {
    max-width: initial;
    transform: scale(0.5);
  }

  img {
    position: absolute;

    &:nth-of-type(1) {
      width: 315px;
      height: 315px;

      @media (max-width: 768px) {
        width: 155px;
        height: 155px;
        transform: translateY(25%);
      }
      @media (max-height: 425px) {
        transform: scale(0.8);
      }
    }

    &:nth-of-type(2) {
      right: 0;
      bottom: 0;
      width: 155px;
      height: 155px;

      // Default position.
      transform: scale(-1, 1);

      @media (max-width: 768px) {
        width: 74px;
        height: 74px;
      }
      @media (max-height: 425px) {
        right: -100px;
        transform: scale(0.6);
      }
    }

    &:nth-of-type(3) {
      top: 60px;
      right: 110px;
      width: 110px;
      height: 110px;

      // Default position.
      transform: scale(-1, 1) rotate(25deg);

      @media (max-width: 768px) {
        width: 55px;
        height: 55px;
      }
      @media (max-height: 425px) {
        top: -50px;
        right: 60px;
      }
    }

    &:nth-of-type(4) {
      left: 3%;
      bottom: 33%;
      width: 95px;
      height: 95px;

      // Default position.
      transform: rotate(-20deg);

      @media (max-width: 768px) {
        width: 44px;
        height: 44px;
      }
    }
  }
}

.desc {
  max-width: 1213px;
  max-height: 0;
  text-align: center;
  opacity: 0;

  animation: showDesc 2s ease-in-out forwards alternate;
  @keyframes showDesc {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 1440px) {
    font-size: 22px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }

}

.button-next {
  position: fixed;
  right: 100px;
  bottom: 100px;
  opacity: 1;
  transform: scale(0);

  @media (max-width: 1440px) {
    right: 40px;
    bottom: 40px;
  }
  @media (max-width: 768px) {
    right: auto;
    left: auto;
    bottom: 20px;
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    bottom: 30px;
    right: 30px;
  }
}

.button {
  position: fixed;
  right: 100px;
  bottom: 100px;
  @media (max-width: 1440px) {
    right: 40px;
    bottom: 40px;
  }
  @media (max-width: 768px) {
    right: auto;
    left: auto;
    bottom: 20px;
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    bottom: 30px;
    right: 30px;
  }
}

</style>
