<script setup>
import Title from "@/components/BVI/UI/Title.vue";
import {defineEmits, defineProps} from 'vue';
import Button from "@/components/BVI/UI/Button.vue";

const emit = defineEmits(["buttonClicked"]);

const props = defineProps({
  buttons: {
    type: Array,
    required: true,
  },
  title: String,
})
</script>

<template>

  <div class="buttons">
    <Title v-if="title" :title="title" />
    <div class="buttons-wrap">
      <Button v-for="(button, i) in props.buttons"
              v-bind:key="i"
              :button="button"
              @click="emit('buttonClicked', button)"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
  .buttons {
    flex: 0 0 auto;
    &-wrap {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
</style>