<script setup>
import {defineProps, defineEmits} from 'vue';
import speak from "../../../../plugins/speak";

const emit = defineEmits(["click"]);

const props = defineProps({
  button: {
    type: Object,
    required: false,
  },
})
</script>

<template>
  <button class="button_bvi"
          :title="props.button.text"
          @mouseover="speak"
          @click="()=>{emit('click')}"
  >
    <img v-if="props.button.icon" :src="props.button.icon" :alt="props.button.text">
    {{props.button.text}}
  </button>
</template>

<style scoped lang="scss">
.button_bvi {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #FFF;
  color: #000;
  padding: 17px 40px;
  border-radius: 16px;
  max-width: calc(100vw - 120px);
  text-align: left;
  transition: all 0.3s ease-in-out;
  img {
    width: 40px;
    border-radius: 50%;
  }
  @media (max-width: 1280px) {
    font-size: 20px;
  }
  &:hover {
    background-color: #F15A29;
    color: #FFF;
  }
}
</style>