import store from "@/store";
const speak = (event) => {
    console.log(localStorage.getItem('bviSpeech'));
    if (localStorage.getItem('bviSpeech') === 'true') {
        const text = event.target.textContent;
        const utterance = store.getters.getSpeaker;
        if(utterance) {
            if (store.getters.isTalking) {
                window.speechSynthesis.cancel();
            }
            utterance.text = text;
            utterance.lang = "ru";
            utterance.rate = 1.5;
            utterance.onstart = store.commit('switchTalking', true)
            window.speechSynthesis.speak(utterance);
            utterance.onend = () => store.commit('switchTalking', false);
        }
        else{
            console.log("Feature not supported");
        }
    }
}

export default speak;