<script setup>

import Interface from "@/components/BVI/HUD/Interface.vue";
import Dialog from "@/components/BVI/Dialog/Dialog.vue";
import Buttons from "@/components/BVI/Dialog/Buttons.vue";
import {onMounted, ref} from "vue"
import store from "@/store";
import Message from "@/components/BVI/UI/Message.vue";
import DialogBubble from "@/components/BVI/Dialog/DialogBubble.vue";
import authService from "../../../plugins/auth";
import {v4 as uuidv4} from "uuid";
import Button from "@/components/BVI/UI/Button.vue";
import router from "@/router";

const isLoading      = ref(true);
const userId = ref(null);
const shareText = 'Какой ты стажер? Посмотри, какие ачивки у меня сегодня👀 Залетай в игру и получай призы вместе со мной🚀';

const hashedId = btoa(store.getters.getUserId);
const shareLink = `${window.location.origin}${process.env.BASE_URL}share?screen=${hashedId}`;
const buttons = ref([
    {
      icon: "/assets/images/UI/icons/vk.png",
      text: "ВКОНТАКТЕ",
      link: `https://vk.com/share.php?comment=${shareText}&url=${shareLink}`,
    },
    {
      icon: "/assets/images/UI/icons/telegram.png",
      text: "ТЕЛЕГРАМ",
      link: `tg://msg_url?text=${shareText}&url=${shareLink}`,
    },
    {
      icon: "/assets/images/UI/icons/share.png",
      text: "СКОПИРОВАТЬ ССЫЛКУ",
      copy: shareLink,
    }
])

const buttonAction = async (button)=> {

  if (button.link) {
    window.open(button.link, "_blank");
  }
  if (button.copy) {
    store.commit('setMessage', '<p class=\'h4\'>ССЫЛКА СКОПИРОВАНА!</p>');
    await navigator.clipboard.writeText(button.copy);
    setTimeout(()=>{
      store.commit('setMessage', null);
    }, 2000)
  }

}

const getGameStatus = async () => {
  try {
    if (process.env.NODE_ENV == 'production') {
      await authService.getProfile();
      userId.value = await store.dispatch('getUserId');
    } else {
      userId.value = uuidv4()
    }
    store.dispatch('setUserIdAction', userId.value)
    await store.dispatch('loadGameStatusAction', userId.value)
    isLoading.value = false
  } catch (error) {
    console.log(error)
  }
}
const restartGame = async () => {
  try {
    await store.dispatch('restartGameAction');
    router.push('/bvi/playing/');
  } catch (e) {
    console.log(e)
  }
}
onMounted(async () => {
  try {
    await getGameStatus();
  } catch (error) {
    console.log(error)
  }
})

</script>

<template>
  <main id="work_place" class="bvi-layout bvi-no-styles">
    <div v-if="!isLoading" class="container bvi-no-styles">
      <Interface />
      <Message />
      <div class="row bvi-no-styles">
        <div class="sharing-buttons">
          <Dialog>
            <template v-slot:dialog>
              <Buttons
                  title="Поделиться с друзьями:"
                  :buttons="buttons"
                  @buttonClicked="(button)=>buttonAction(button)"
              />
            </template>
          </Dialog>
        </div>
        <Dialog
            v-if="store.getters.getAchievements.length > 0"
        >
          <template v-slot:dialog>
            <DialogBubble name="Предметы на вашем рабочем столе:">
              <ul>
                <li v-for="achievement in store.getters.getAchievements" :key="achievement.name">
                  {{store.getters.getAchievement(achievement).name}} -
                  {{store.getters.getAchievement(achievement).description}}
                </li>
              </ul>
            </DialogBubble>
          </template>
        </Dialog>
      </div>
      <div v-if="store.getters.getNotComplitedActivities.length == 0 && store.getters.getPrize" class="new-game message bvi-no-styles">
        <Button :button="{
          text: 'Играть еще раз'
        }"
                @click="restartGame()"
        ></Button>
      </div>
    </div>
  </main>
</template>

<style scoped lang="scss">
#work_place {
  background-image: url('/assets/images/WorkPlace/work_place.png');
  position: relative;
  .container {
    position: relative;
    gap: 50px;
    justify-content: flex-start;
    .row {
      flex: 1;
      flex-wrap: wrap;
      gap: 80px;
      > *:first-child {
        align-self: flex-end;
        flex: 0 0 auto;
      }
      > *:last-child {
        flex: 1;
      }
    }
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    opacity: .6;
  }
  @media (max-width: 900px) {
    .sharing-buttons {
      order: 1;
    }
  }
}
</style>