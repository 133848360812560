import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "@/store";

import { defineCustomElements as defineIonPhaser } from '@ion-phaser/core/loader'

defineIonPhaser(window);

const app = createApp(App);
app.use(store);
app.use(router);
app.mount('#app');
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("ion-");


