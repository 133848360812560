<script setup>
import { useStore } from 'vuex'

import { useRouter } from 'vue-router'
import { ref, inject, onMounted, defineExpose } from 'vue'

import gsap from "gsap";
import CustomEase from "/plugins/gsap/CustomEase.min.js"

gsap.registerPlugin(CustomEase)

// Route ----------------------------------------------------
const router = useRouter();

// Store: State/Getters/Actions -----------------------------
const store = useStore()

// Variables: -----------------------------------------------
let drum_rotate_complete = ref(false);
let drum_rotate_success  = ref(false);
let drumRotate           = ref(); // is the function (call from parent "Prize.vue")

const cards = 51
const speed = 7;

// Functions: -----------------------------------------------
const inject_error = inject("showErrorMessage");

const goToTheNextPage  = () => router.push({ path: "/end/" })
const showErrorMessage = (message) => {
  inject_error(true);
  console.log(message)
}
const getPrize         = async () => {
  try {
    await store.dispatch('setPrizeAction');
    drum_rotate_success.value = true;
    setTimeout(() => {
      goToTheNextPage();
    }, 3500)
  } catch (e) {
    showErrorMessage(e)
    throw e;
  }
};

// Hooks: ---------------------------------------------------
onMounted(() => {
  drumRotate.value = () => {
    // Call from parent "Prize.vue"

    CustomEase.create("custom", "M0,0 C0.133,0.426 0.175,0.587 0.34,0.866 0.408,0.982 0.65,1.18 1,1.203");
    const animation = value => {
      return {
        xPercent: value * (cards - 7), duration: speed, ease: "custom", onComplete: () => getPrize()
      }
    }

    if (drum_rotate_complete.value) {
      gsap.to(".card-container", animation(50));
      drum_rotate_complete.value = false
    } else {
      gsap.to(".card-container", animation(-50));
      drum_rotate_complete.value = true
    }

  }
})

defineExpose({ drumRotate })

</script>

<template>
  <div :class="['drum-container', { 'rotate-success' : drum_rotate_success } ]">
    <div class="drum">
      <div class="card-container" v-for="i in cards" :key="i" :data-card="i">
        <div class="card"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.drum-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  min-height: 340px;
  height: auto;
  @media (orientation: landscape) {
    min-height: 210px;
  }
  @media (max-width: 768px) {
    min-height: 210px;
  }
  @media (max-width: 520px) {
    min-height: 160px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, var(--bg-main) 0%, rgba(16, 4, 35, 0) 51%, var(--bg-main) 100%);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 100%;
    max-height: 345px;
    background: url("~@/assets/images/drum-stop-line.svg") no-repeat 50% 50%/contain;
    z-index: 1;
    transform: translate(-50%, -50%);
    opacity: .7;
  }
}

.drum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  .card-container {
    flex-shrink: 0;
    width: 100%;
    height: 280px;
    max-width: 240px;
    padding: 0 15px;

    @media (orientation: landscape) {
      height: 180px;
      max-width: 140px;
      padding: 0 5px;
    }
    @media (max-width: 768px) {
      height: 180px;
      max-width: 140px;
      padding: 0 5px;
    }
    @media (max-width: 520px) {
      height: 140px;
      max-width: 100px;
    }
  }

  .card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: #000;
    font-size: 40px;
    background: white;
    border-radius: 23px;
    background: url("~@/assets/images/card.png") no-repeat 50% 50%/contain;

    @media (max-width: 768px) {
      border-radius: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("~@/assets/images/card-active.png") no-repeat 50% 50%/contain;
      transition: all .55s linear;
      opacity: 0;
    }
  }
}

.rotate-success {
  .card-container:nth-last-child(4) {
    .card {
      &::after {
        opacity: 1;
      }
    }
  }
}

</style>
