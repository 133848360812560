<template>
  <Share/>
</template>

<script>
import Share from "@/game/Share";
export default {
  name: "ShareScreen",
  components: {
    Share
  }
}
</script>

<style scoped>

</style>