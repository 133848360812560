const state = {
    dialogPapers: [
        {
            sprite: 'papers_animation',
            size: {frameWidth: 724, frameHeight: 484},
            x: 1080,
            y: 354,
            frameNumbers: {
                start: 1,
                end: 5
            },
            frameRate: 4,
            repeat: -1
        }
    ],
    dialogPrinter: [
        {
            sprite: 'printer_animation',
            size: {frameWidth: 759, frameHeight: 926},
            x: 1057,
            y: 134,
            frameNumbers: {
                start: 0,
                end: 7
            },
            frameRate: 7,
            repeat: -1,
            depth: 1
        }
    ],
    dialogPlanningUp: [
        {
            sprite: 'planningUp_animation',
            size: {frameWidth: 889, frameHeight: 637},
            x: 1011,
            y: 226,
            frameNumbers: {
                start: 1,
                end: 13
            },
            frameRate: 2,
            repeat: -1,
        }
    ],
    dialogComrades: [
        {
            sprite: 'comrades_animation',
            size: {frameWidth: 860, frameHeight: 1060},
            x: 1040,
            y: 0,
            frameNumbers: {
                start: 1,
                end: 8
            },
            frameRate: 6,
            repeat: 0,
            depth: 1
        }
    ],
    dialogConditioner: [
        {
            sprite: 'web_animation',
            size: {frameWidth: 395, frameHeight: 535},
            x: 1505,
            y: 105,
            frameNumbers: {
                start: 1,
                end: 19
            },
            frameRate: 10,
            repeat: -1,
            depth: 1
        },
        {
            sprite: 'bird_animation',
            size: {frameWidth: 113, frameHeight: 223},
            x: 557,
            y: 195,
            frameNumbers: {
                start: 1,
                end: 11
            },
            frameRate: 10,
            repeat: -1,
            depth: 1
        },
    ]
}

const getters = {
    getAnimations: state => animations => {
        return state[animations];
    }
}

export default {
    state,
    getters
};