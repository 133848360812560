<script setup>
import {defineProps} from 'vue';
import Title from "@/components/BVI/UI/Title.vue";
import speak from "../../../../plugins/speak";

const props = defineProps({
  name:  {
    type: String,
    required: true
  },

})
</script>

<template>
  <div class="dialog-bubble">
    <Title :title="props.name" />
    <div class="dialog-bubble_text" @mouseover="speak">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .dialog {
    .dialog-bubble_text {
      padding: 0;

    }
  }
  .dialog-bubble {
    ul {
      li {
        list-style-type: disc;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
    }
    //min-width: 50vw;
    flex: 1;
    display: flex;
    flex-direction: column;
    &_text {
      padding: 40px;
      border-radius: 16px;
      p {
        margin-bottom: 10px;
      }
    }
  }
</style>