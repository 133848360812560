<template>
  <div v-if="isMobile" class="screen_rotate_device">
    <screenRotateDevice/>
  </div>

  <div>
    <div v-if="gameReady" class="game-container">
      <ion-phaser
          v-bind:game.prop="game"
          v-bind:initialize.prop="true"
      />

    </div>
    <div v-else class="loading">
      <img :src="loadingImage" alt="">
    </div>
    <div v-if="debug" class="debug">
      <p>Ширина окна: {{ game.scale.width }}</p>
      <p>Высота окна: {{ game.scale.height }}</p>
    </div>
  </div>
</template>

<script>
import Phaser from "phaser";
import WebFontLoaderPlugin from "phaser3-rex-plugins/plugins/webfontloader-plugin";
import {computed, onMounted, ref} from "vue";
import store from '@/store';
import preloader from '@/game/assets/loading.gif'
import SharedWorkPlaceScene from "./scenes/SharedWorkPlaceScene";

export default {
  setup() {
    const debug        = false
    const game         = {
      type: Phaser.AUTO,
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        parent: 'game-container',
        width: 1900,
        height: 1060
      },
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 300 },
        }
      },
      scene: [ SharedWorkPlaceScene ],
      plugins: {
        global: [
          {
            key: 'rexWebFontLoader',
            plugin: WebFontLoaderPlugin,
            start: true
          },
        ]
      }
    }
    let isLoading      = ref(true);
    let userId         = ref(null);
    const loadingImage = preloader

    let isMobile = ref(false)

    const getGameStatus = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.has('screen')) {
          userId = atob(urlParams.get('screen'));
          await store.dispatch('loadGameSharedStatusAction', userId);
        } else {
          userId = localStorage.getItem('userId')
        }
        store.dispatch('setUserIdAction', userId)
        isLoading.value = false
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await getGameStatus()
    });

    const gameReady = computed(() => {
      return !isLoading.value;
    })

    // Attention: "Rotate device".
    window.addEventListener('resize', () => isMobile.value = window.innerWidth <= 420)

    return {
      debug,
      game,
      userId,
      isLoading,
      gameReady,
      getGameStatus,
      loadingImage,
      isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  height: 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;

  img {
    width: 150px;
    height: auto;
  }
}

.debug {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 15px;
}

.screen_rotate_device {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-main);
  z-index: 1000;
}
</style>