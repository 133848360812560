import { UserManager, WebStorageStateStore } from 'oidc-client-ts'
import store from "@/store";

const settings = {
    // Where the tokens will be stored
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    // URL to the authentication server (including realm)
    authority: process.env.VUE_APP_AUTH_URL,
    // The name of the client in Keycloak setup for this service
    client_id: process.env.VUE_APP_AUTH_CODE,
    // Where to redirect the user to after successful authentication
    redirect_uri: process.env.VUE_APP_AUTH_REDIRECT,
    // Where to redirect the user to after logging the user out
    post_logout_redirect_uri: process.env.VUE_APP_AUTH_LOGOUT,
    // Indicate the authorization code flow should be used
    response_type: 'code',
    // "openid" tells the server that this client uses oidc for authentication
    scope: 'openid profile',
    // Включение PKCE в настройках клиента
    automaticSilentRenew: true,
    accessTokenExpiringNotificationTime: 60,
}

let userManager = new UserManager(settings)

/**
 * Class to encapsulate all authentication related logic.
 */
class AuthService {
    /**
     * Initate the login process.
     */
    login () {
        userManager.signinRedirect()
            .catch(error => console.log(error))
    }

    logout () {
        userManager.signoutRedirect()
            .then(() => console.log('User logged out'))
            .catch(error => console.log(error))
    }

    /**
     * Handles the redirect from the OAuth server after a user logged in.
     */
    handleLoginRedirect () {
        // Returns a promise
        return userManager.signinRedirectCallback()
    }

    /**
     * Handles the redirect from the OAuth server after a user logged out.
     */
    handleLogoutRedirect () {
        return userManager.signoutRedirectCallback()
    }

    /**
     * Checks whether or not a user is currently logged in.
     *
     * Returns a promise which will be resolved to true/false or be rejected with an error.
     */
    isUserLoggedIn () {
        return new Promise((resolve, reject) => {
            userManager.getUser()
                .then(user => {
                    if (user === null) {
                        resolve(false)
                    }
                    resolve(true)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }

    /**
     * Get the profile data for the currently authenticated user.
     *
     * Returns an empty object if no user is logged in.
     */
    getProfile () {
        return new Promise((resolve, reject) => {
            userManager.getUser()
                .then(user => {
                    if (user === null) {
                        resolve(null)
                    }
                    store.commit('setAccessToken', user.access_token);
                    resolve(user.profile)
                })
                .catch(error => reject(error))
        })
    }
    /**
     * Get the access token.
     *
     * Can be used to make requests to the backend.
     */
    getAccessToken () {
        return new Promise((resolve, reject) => {
            console.log('Get access token from user')
            userManager.getUser()
                .then(user => {
                    console.log('Got access token from user')
                    resolve(user.access_token)
                })
                .catch(error => reject(error))
        })
    }
}

/**
 * Create and expose an instance of the auth service.
 */
export const authService = new AuthService();

export default authService