export default class sceneAnimation {
    constructor(scene, animations) {
        this.scene = scene;
        this.animations = Array.isArray(animations) ? animations : [animations];
    }

    addAnimations() {
        this.animations.forEach(animation=>{
            const sprite = this.scene.add.sprite(
                animation.x + (animation.size.frameWidth / 2),
                animation.y + (animation.size.frameHeight / 2),
                animation.sprite
            )

            if (typeof animation.depth !== 'undefined') {
                sprite.setDepth(animation.depth);
            }

            if (typeof animation.scale !== 'undefined') {
                sprite.setScale(animation.scale);
            }

            this.scene.anims.create({
                key: `${animation.sprite}_animation`,
                frames: this.scene.anims.generateFrameNumbers(animation.sprite, animation.frameNumbers),
                frameRate: animation.frameRate,
                repeat: animation.repeat
            })

            sprite.play(`${animation.sprite}_animation`);
        })
    }

    addAnimationsJSON() {
        this.animations.forEach(animation=>{
            const sprite = this.scene.add.sprite(
                animation.x + (animation.size.frameWidth / 2),
                animation.y + (animation.size.frameHeight / 2),
                this.scene.activity,
                `${animation.sprite}/1.png`
            )

            if (typeof animation.depth !== 'undefined') {
                sprite.setDepth(animation.depth);
            }

            if (typeof animation.scale !== 'undefined') {
                sprite.setScale(animation.scale);
            }

            let frames = [];

            for (let i = 1; i <= animation.frameNumbers.end; i++) {
                frames.push({ key:this.scene.activity, frame:`${animation.sprite}/${i}.png` },);
            }

            this.scene.anims.create({
                key: `${animation.sprite}_animation`,
                frames: frames,
                frameRate: animation.frameRate,
                repeat: animation.repeat
            })

            sprite.play(`${animation.sprite}_animation`);
        })
    }
}