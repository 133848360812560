import {createStore} from 'vuex'
import game_status from "./modules/game_status";
import dialogs from "./modules/dialogs";
import statusNames from "./modules/statusNames"
import animations from "./modules/animations";
import achievements from "./modules/achievements";
import speaker from "./modules/speaker";

const store = createStore({
    state () {
        return {
            messageText: null
        }
    },
    mutations: {
        setMessage (state, message) {
            state.messageText = message
        }
    },
    getters: {
        getMessage (state) {
            return state.messageText
        }
    },
    modules: {
        game_status,
        dialogs,
        statusNames,
        animations,
        achievements,
        speaker
    }
})

export default store;
