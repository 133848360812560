<script setup>
import { onMounted } from "vue";
import gsap from "gsap";

onMounted(() => {
  const animations = () => {
    let tl = gsap.timeline()
    tl.to(".h4", { y: 0, opacity: 1, duration: .5, delay: .5 })
    tl.to(".h2", { y: 0, opacity: 1, duration: .5 }, "<")
    tl.to(".desc", { y: 0, opacity: 1, duration: .5, delay: .5 })
    tl.to(".img", { y: 0, opacity: 1, duration: .5 }, "<")
    tl.to(".button", { y: 0, opacity: 1, duration: .5 }, "<")
  }
  animations();
})
</script>

<template>
  <main class="main">
    <section class="container">
      <div class="content">
        <h4 class="h4">МЕСТО ТВОЕЙ СТАЖИРОВКИ</h4>
        <h2 class="h2">КОМПАНИЯ МЕЧТЫ ПЛЮС</h2>
        <div class="desc">
          <p>Здесь каждый сотрудник чувствует себя ценным и&nbsp;важным, развивается и&nbsp;растёт профессионально. Гибкий график позволяет сочетать работу и&nbsp;личную жизнь, а&nbsp;уникальные проекты дарят возможность реализовывать свои идеи и&nbsp;творческий потенциал.</p>
          <p>А&nbsp;ты&nbsp;как хотел? В&nbsp;проекте &laquo;Профразвитие&raquo; тебя ждут только классные компании.</p>
        </div>
        <img class="img" :src="require('/src/assets/images/KMP.png')" alt="Компания мечты плюс">
        <router-link class="button" title="На стажировку" to="/playing">НА СТАЖИРОВКУ</router-link>
      </div>
    </section>
  </main>
</template>

<style lang="scss" scoped>

.main {
  display: flex;
  height: auto;
  min-height: 100vh;
  align-items: center;
  overflow: hidden;

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    min-height: initial;
    align-items: initial;
  }

}

.content {
  display: grid;
  grid-template-columns: minmax(auto, 850px) minmax(560px, 590px);
  grid-template-rows: 35px auto;
  justify-content: space-between;
  grid-gap: 30px 80px;
  height: 100%;
  width: 95%;
  padding: 40px 0;

  @media (max-width: 1440px) {
    grid-template-columns: 1.3fr 0.7fr;
    grid-template-rows: 35px min-content;
    grid-gap: 30px;
    height: min-content;
  }
  @media (max-width: 768px) {
    grid-template-rows: auto;
    grid-gap: 20px;
    width: 100%;
  }
  @media (max-width: 425px) {
    grid-template-columns: auto;
    grid-template-rows: 20px auto auto auto;
    grid-gap: 15px;
  }
  @media (max-width: 370px) {
    padding: 30px 0;
  }

  @media screen and (orientation: landscape) and (max-width: 1024px) {
    grid-template-columns: 1fr 0.7fr;
    grid-template-rows: auto;
    justify-content: initial;
    grid-gap: 15px;
  }

  .h4 {
    grid-area: 1/1/2/2;

    @media (max-width: 1280px) {
      grid-area: 1/1/2/3;
    }
    @media (max-width: 768px) {
      text-align: center;
    }
  }

  .h2 {
    grid-area: 2/1/3/2;
    margin-bottom: auto;

    @media (max-width: 1280px) {
      grid-area: 2/1/3/3;
    }
    @media (max-width: 768px) {
      text-align: center;
    }

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      grid-area: 2/1/3/2;
    }

  }

  .desc {
    grid-area: 3/1/5/2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 800px;
    padding-top: 30px;
    @extend %font-inter-300;
    font-size: 34px;
    line-height: 1.17;

    @media (max-width: 1440px) {
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      grid-area: 3/1/4/2;
      max-width: initial;
      justify-content: initial;
      padding-top: initial;
    }
    @media (max-width: 1024px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      grid-area: 4/1/5/3;
      text-align: center;
    }
    @media screen and (orientation: landscape) and (max-width: 1024px), (max-width: 425px) {
      font-size: 12px;
    }

    p:not(:last-child) {
      margin-bottom: 25px;

      @media (max-width: 370px) {
        margin-bottom: 15px;
      }
    }
  }

  .img {
    grid-area: 1/2/4/3;
    width: 100%;
    max-width: 535px;
    height: 100%;
    margin: 0 auto 80px auto;
    object-fit: contain;
    object-position: center center;

    @media (max-width: 1440px) {
      max-width: 300px;
    }
    @media (max-width: 1280px) {
      grid-area: 3/2/5/3;
      max-width: initial;
      margin-bottom: initial;
      height: calc(100% - 100px);
    }
    @media (max-width: 1024px) {
      max-width: 260px;
    }
    @media (max-width: 768px) {
      grid-area: 3/1/4/3;
      height: 100%;
      max-height: 240px;
      margin: 20px auto 0 auto;
    }
    @media (max-width: 370px) {
      height: 150px;
      margin-top: initial;
    }

    @media screen and (orientation: landscape) and (max-width: 1024px) {
      grid-area: 1/2/5/3;
      height: 100%;
      max-width: 80%;
    }

  }

  .button {
    grid-area: 4/2/5/3;

    @media (max-width: 1440px) {
      height: 70px;
      padding: 0 30px;
      font-size: 26px;
    }
    @media (max-width: 1280px) {
      grid-area: 4/1/5/2;
      max-width: max-content;
      height: 50px;
    }
    @media (max-width: 1024px) {
      padding: 0 20px;
      font-size: 18px;
    }
    @media (max-width: 768px) {
      grid-area: 5/1/5/3;
      max-width: min-content;
      margin: 0 auto;
      font-size: 18px;
    }

  }

}

.h4, .h2, .desc, .button {
  opacity: 0;
  transform: translateY(15%);
}

.img {
  opacity: 0;
  transform: translateY(5%);
}

</style>
