import {Scene} from 'phaser';
import store from "@/store";

import Dialog from "../../modules/dialog";
import StatusesPanel from "@/game/utils/statusesPanel";
import Achievements from "@/game/utils/achievements";
import AssetsLoader from "@/game/utils/AssetsLoader";
import sceneAnimation from "@/game/utils/sceneAnimation";
import UI from "@/game/utils/UI"

//Assets
import open_space from '@/game/assets/OpenSpace/open_space.png';
import leha from '@/game/assets/NPC/Leha.png';


export default  class DialogScene extends  Scene {
    constructor() {
        super({key: 'DialogScene'});
        this.activity = null;
        this.dialogData = null;
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload() {
        this.load.image('open_space', open_space)
        this.load.image('leha', leha);
        this.assetsLoader.loadAssets()
    }

    create() {
        this.activity = store.getters.getCurrentActivity;
        this.dialogData = store.getters.getDialog(this.activity);
        this.spritesheet = this.dialogData.spritesheet ? this.dialogData.spritesheet : 'dialogDefault';

        const background = this.dialogData.background ? this.dialogData.background : 'open_space';

        this.add.sprite(0, 0, this.spritesheet, `${background}.png`).setOrigin(0);

        if (this.dialogData.animations) {
            const animations = new sceneAnimation(this, store.getters.getAnimations(this.dialogData.animations));
            animations.addAnimationsJSON();
        }

        if (this.activity !== 'dialogAlexGreetings') {
            this.cameras.main.fadeIn(300, 0, 0, 0, );
        } else {
            store.dispatch('setNeedGreetingsAction', false)
        }

        this.statuses = new StatusesPanel(this);
        this.statuses.addStatusesUI()

        this.achievements = new Achievements(this);

        if (this.dialogData.npcImage) {
            var npc = this.add.image(0, 0, this.dialogData.npcImage).setOrigin(0).setInteractive()

            npc.setPosition(this.scale.width - npc.width, this.scale.height - npc.height)

            npc.setDepth(2)
        }

        const FKey = this.input.keyboard.addKey('F');

        FKey.on('down', function ()
        {

            if (this.scale.isFullscreen)
            {
                this.scale.stopFullscreen();
            }
            else
            {
                this.scale.startFullscreen();
            }

        }, this);

        const dialog = new Dialog(this, this.dialogData)
        dialog.createDialog();
        this.sceneUI.addScreeenMode();
    }


}