import {Scene, Utils} from 'phaser'
import Card from "@/game/scenes/games/MemoryGameScene/modules/Card"
import store from "@/store";
import AssetsLoader from "@/game/utils/AssetsLoader";
import UI from "@/game/utils/UI"

import bg from '@/game/assets/Games/Memory/bg.png'
import card from '@/game/assets/Games/Memory/card.png'
import card1 from '@/game/assets/Games/Memory/card1.png'
import card2 from '@/game/assets/Games/Memory/card2.png'
import card3 from '@/game/assets/Games/Memory/card3.png'
import card4 from '@/game/assets/Games/Memory/card4.png'
import card5 from '@/game/assets/Games/Memory/card5.png'
import card6 from '@/game/assets/Games/Memory/card6.png'
import card7 from '@/game/assets/Games/Memory/card7.png'
import card8 from '@/game/assets/Games/Memory/card8.png'
import cardSound from '@/game/assets/sound/card.mp3'
import complete from '@/game/assets/sound/complete.mp3'
import success from '@/game/assets/sound/success.mp3'
import theme from '@/game/assets/sound/theme.mp3'
import timeout from '@/game/assets/sound/timeout.mp3'


export default class Index extends Scene {

    constructor(){
        super({key: 'MemoryGameScene'})
        this.config = {
            rows: 3,
            cols: 4,
            cards: [1, 2, 3, 4, 5, 6],
            timeout: 35,
        }
        this.assetsLoader = new AssetsLoader(this);
        this.sceneUI = new UI(this);
    }

    preload(){
        this.load.image('bg', bg)
        this.load.image('card', card)
        this.load.image('card1', card1)
        this.load.image('card2', card2)
        this.load.image('card3', card3)
        this.load.image('card4', card4)
        this.load.image('card5', card5)
        this.load.image('card6', card6)
        this.load.image('card7', card7)
        this.load.image('card8', card8)

        this.load.audio('card', cardSound)
        this.load.audio('complete', complete)
        this.load.audio('success', success)
        this.load.audio('theme', theme)
        this.load.audio('timeout', timeout)

    }

    createText(){
        this.timeoutText = this.add.text(10, 330, '',{
            font: '36px Linearo',
            fill: '#ffffff'
        } )
    }

    onTimerTick(){
        this.timeoutText.setText(`Time: ${this.timeout}`)
        if (this.timeout < 1) {
            this.timer.paused = true
            this.sounds.timeout.play()
            this.restart()
        } else {
            --this.timeout
        }
    }

    createTimer(){
        this.timer = this.time.addEvent({
            delay: 1000,
            callback: this.onTimerTick,
            callbackScope: this,
            loop: true
        })
    }

    createSounds() {
        this.sounds = {
            card: this.sound.add('card'),
            complete: this.sound.add('complete'),
            success: this.sound.add('success'),
            theme: this.sound.add('theme'),
            timeout: this.sound.add('timeout')
        }
    }

    create(){
        this.timeout = this.config.timeout
        this.createSounds()
        this.createTimer()
        this.createBackground()
        this.createText()
        this.createCards()
        this.start()
        this.sceneUI.addScreeenMode();
    }

    restart(){
        this.sounds.theme.stop();
        store.commit('setCurrentActivity', 'dialogComradesFinish');
        this.scene.start('DialogScene');
        let count = 0
        let onCardMoveComplete = () => {
            ++count
            if (count >= this.cards.length){
                this.start()
            }

        }
        this.cards.forEach(card => {
            card.move({
                x: this.sys.game.config.width + card.width,
                y: this.sys.game.config.height + card.height,
                delay: card.position.delay,
                callback: onCardMoveComplete
            })
        })
        this.start()
    }

    start(){
        this.initCardPositions()
        this.timeout = this.config.timeout
        this.openedCard = null
        this.openCardsCount = 0
        this.timer.paused = false
        this.initCards()
        this.showCards()
    }

    initCards(){
        let positions = Utils.Array.Shuffle(this.positions)

        this.cards.forEach(card => {
            card.init(positions.pop())
        })
    }

    showCards(){
        this.cards.forEach(card => {
            card.depth = card.position.delay
            card.move({
                x: card.position.x,
                y: card.position.y,
                delay: card.position.delay
            })
        })
    }

    createBackground(){
        this.add.sprite(0, 0, 'bg').setOrigin(0, 0)
    }

    createCards(){
        this.cards = [];

        for (let value of this.config.cards) {
            for (let i = 0; i < 2; i++){
                 this.cards.push(new Card(this, value))
            }
        }

        this.input.on("gameobjectdown", this.onCardClicked, this)
    }

    onCardClicked(pointer, card){
        if(card.opened) {
            return false
        }

        this.sounds.card.play()

        if(this.openedCard){
            if(this.openedCard.value === card.value){
                this.sounds.success.play()
                this.openedCard = null
                ++this.openCardsCount
            } else {
                this.openedCard.close()
                this.openedCard = card
            }
        } else {
            this.openedCard = card
        }

        card.open(() => {
            if (this.openCardsCount === this.cards.length / 2) {
                this.sounds.complete.play()
                this.restart()
            }
        })
    }

    initCardPositions(){
        let positions = []
        let paddingCard = 4
        let cardTexture = this.textures.get('card').getSourceImage()
        let cardWidth = cardTexture.width + paddingCard
        let cardHeight = cardTexture.height + paddingCard
        let offsetX = (this.sys.game.config.width - cardWidth * this.config.cols) / 2 + cardWidth / 2
        let offsetY = (this.sys.game.config.height - cardHeight * this.config.rows) / 2 + cardHeight /2
        let id = 0

        for (let row = 0; row < this.config.rows; row++) {
            for (let col = 0; col < this.config.cols; col++) {
                ++id
                positions.push({
                    x: offsetX + col * cardWidth,
                    y: offsetY + row * cardHeight,
                    delay: id * 100
                })
            }
        }
        this.positions =  positions
    }
}
