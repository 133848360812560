class MobileJoystick {
    constructor(buttons) {
        this.buttons = buttons;

        this.upDown = false
        this.downDown = false
        this.leftDown = false
        this.rightDown = false
        this.actionDown = false
    }
    addJoystic() {
        this.buttons.forEach(button=>{
            button.element.on('pointerdown', ()=>{
                this[button.role] = true;
            })
            button.element.on('pointerup', ()=>{
                this[button.role] = false;
            })
        })
    }
}

export default MobileJoystick;