<script setup>
</script>

<template>
  <div class="block-message">
    <h2 class="h2">ВНИМАНИЕ!!!</h2>
    <img class="img" :src="require('/src/assets/images/attention.svg')" alt="внимание attention uwaga">
    <div class="desc">
      <p>Мы&nbsp;делаем не&nbsp;просто игру, а&nbsp;целый мини-сериал. Поэтому если играешь со&nbsp;смартфона, то&nbsp;переверни его в&nbsp;горизонтальное положение и&nbsp;перейди в&nbsp;полноэкранный режим, так будет проще читать реплики персонажей.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.block-message {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 40px 0;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  background: var(--bg-main);
  @media (max-width: 1024px) and (orientation: portrait)  {
    display: flex;
  }
  .h2 {
    text-align: center;
    margin-bottom: 30px;

    @media (max-height: 380px) {
      font-size: 20px;
    }
  }

  .img {
    @media (max-height: 380px) {
      max-height: 120px;
    }
  }

  .desc {
    padding: 30px 25px 0 25px;
    margin-bottom: 30px;
    text-align: center;

    @media (max-height: 380px) {
      padding: 20px 25px 0 25px;
      font-size: 12px;
    }

  }

}

</style>
